import Logout from 'screen/auth/logout';
import HomePage from '../screen/personal/basicInformation/home';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LoginPage from 'screen/auth/login';
import RegisterPage from 'screen/auth/register';
import ForgotPasswordPage from 'screen/auth/forgotPassword';
import DashBoardPage from 'screen/dashBoard';
import BasicInformationPage from 'screen/personal/basicInformation';
import SelecCollegePage from 'screen/selectCollege/SelecCollegePage';
import ContactDetailPage from 'screen/personal/contactDetail/ContactDetailPage';
import Demographics from 'screen/personal/demographics/Demographics';
import Citizenship from 'screen/personal/citizenship/Citizenship';
import Household from 'screen/family/household/Household';
import Parent1 from 'screen/family/parent/Parent1';
import Parent2 from 'screen/family/parent/Parent2';
import Siblings from 'screen/family/siblings/Siblings';
import HighSchool from 'screen/education/highSchool/HighSchool';
import CollegeAndUniversity from 'screen/education/CollegeAndUniversity/CollegeAndUnitversity';
import FuturePlan from 'screen/education/FuturePlan/FuturePlan';
import GeneralInfoTest from 'screen/testGroup/GeneralInfo/GeneralInfo';
import SATTest from 'screen/testGroup/SAT/SATTest';
import SATSubjectTest from 'screen/testGroup/SATSubject/SATSubjectTest';
import ACTTest from 'screen/testGroup/ACT/ACTTest';
import ACTSubjectTest from 'screen/testGroup/ACTSubject/ACTSubjectTest';
import IELTSAndPTE from 'screen/testGroup/IELTSAndPTE/IELTSAndPTE';
import TOEFL from 'screen/testGroup/TOEFL/TOEFL';
import OtherTest from 'screen/testGroup/OtherTest/OtherTest';
import GeneralInfo from 'screen/course/generalInfo/GeneralInfo';
import NinthGrade from 'screen/course/ninth/NinthGrade';
import TenthGrade from 'screen/course/tenth/TenthGrade';
import EleventhGrade from 'screen/course/eleventh/EleventhGrade';
import TwelvethGrade from 'screen/course/twelveth/TwelvethGrade';
import CollegeCourse from 'screen/course/collegeCourse/CollegeCourse';
import Activities from 'screen/activitiesAndHonors/Activities';
import Honors from 'screen/activitiesAndHonors/Honors';
import AdditionalInfo from 'screen/testGroup/AdditionalInfo';
import ReviewActivityAndHonor from 'screen/summary/ReviewActivityAndHonor';
import Support from 'screen/Support';
import PersonalSetting from 'screen/settings/PersonalSetting';
import ReviewAdditionalInfo from 'screen/summary/ReviewAdditionalInfo';
import IBSubjectTest from 'screen/testGroup/IBSubject/IBSubjectTest';
import DuoLingoTest from 'screen/testGroup/DuoLingoTest/DuoLingoTest';
import Test from 'screen/Test';
import CBOAndFeeWaiver from 'screen/personal/citizenship/CBOAndFeeWaiver';
import StepParent from 'screen/family/stepParent/StepParent';
import LegalGuardian from 'screen/family/stepParent/LegalGuardian';
import CLEP from 'screen/testGroup/CLEP/CLEP';
import EducationalPrograms from 'screen/activitiesAndHonors/EducationalPrograms';
import OtherInformation from 'screen/personal/otherInformation/OtherInformation';
import ReviewStudent from 'screen/counselor/ReviewStudent';
import RPersonal from 'screen/summary/ReviewPersonal';
import ReviewFamily from 'screen/summary/ReviewFamily';
import ReviewEducation from 'screen/summary/ReviewEducation';
import ReviewTest from 'screen/summary/ReviewTest';
import ReviewCourse from 'screen/summary/ReviewCourse';
import Policies from 'screen/Policies';
import MyCollege from 'screen/CollegeManagement/MyCollege';
import CollegeSearch from 'screen/CollegeManagement/CollegeSearch';
import EnrolimentStatusPage from 'screen/education/EnrolimentStatus/EnrolimentStatusPage';
import Payment from 'screen/payment/Payment';
import SuccessPage from 'screen/payment/Success';
import UserPayment from 'screen/settings/UserPayment';
import UserSuccessPayment from 'screen/settings/UserSuccessPayment';
import ReviewGPAForecaster from 'screen/summary/ReviewGPAForecaster';
import GpaForecaster from 'screen/GpaForecaster/GpaForecaster';

//import GPAForecasterSum from 'screen/summary/GPAForecasterSum';

export const userRouters: any = {
  forgotPassword: {
    path: '/public/forgot-password',
    component: ForgotPasswordPage,
  },
  payment: {
    path: '/public/payment',
    component: Payment,
  },
  login: {
    path: '/public/login',
    component: LoginPage,
  },
  register: {
    path: '/public/register',
    component: RegisterPage,
  },
  logout: {
    path: '/public/logout',
    component: Logout,
  },
  privacy: {
    path: '/public/privacy-policies',
    component: Policies,
  },
  success: {
    path: '/public/success',
    component: SuccessPage,
  }

};

export const appRoutes: any = {
  home: {
    path: '/',
    component: HomePage,
  },
  dashBoard: {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashBoardPage,
  },
  // support: {
  //   path: '/support',
  //   name: 'Support',
  //   component: Support,
  // },
  settings: {
    path: '/settings',
    name: 'Settings',
    component: PersonalSetting,
  },
  userPayment: {
    path: '/payment',
    name: 'User Payment',
    component: UserPayment,
  },
  userPaymentCancel: {
    path: '/payment',
    name: 'User Payment',
    component: UserPayment,
  },
  userPaymentSuccess: {
    path: '/success',
    name: 'User Payment Success',
    component: UserSuccessPayment,
  },
  //Personal
  basicInformation: {
    path: '/application/basic-information',
    name: 'Basic Information',
    component: BasicInformationPage,
  },
  contactDetail: {
    path: '/application/contact-details',
    name: 'Contact Details',
    component: ContactDetailPage,
  },
  demographics: {
    path: '/application/demographics',
    name: 'Demographics',
    component: Demographics,
  },
  citizenship: {
    path: '/application/citizenship',
    name: 'Citizenship/Residency',
    component: Citizenship,
  },
  cbo: {
    path: '/application/cbo-and-fee-waiver',
    name: 'CBOs & Fee Waiver',
    component: CBOAndFeeWaiver,
  },
  otherInformation: {
    path: '/application/other-information',
    name: 'Other Information',
    component: OtherInformation,
  },
  //College
  selectCollege: {
    path: '/application/select-college',
    name: 'Select College',
    component: SelecCollegePage,
  },
  collegeSearch: {
    path: '/application/college-search',
    name: 'College Search',
    component: CollegeSearch,
  },
  myCollege: {
    path: '/application/my-college',
    name: 'My Colleges',
    component: MyCollege,
  },
  //Family
  household: {
    path: '/application/household',
    name: 'Household',
    component: Household,
  },
  parentNo1: {
    path: '/application/parent-1',
    name: 'Parent No.1',
    component: Parent1,
    permission: 'parentNo1',
  },
  parentNo2: {
    path: '/application/parent-2',
    name: 'Parent No.2',
    component: Parent2,
    permission: 'parentNo2',
  },
  stepParent1: {
    path: '/application/step-parent-1',
    name: 'Step-Parent 1',
    component: StepParent,
    permission: 'stepParent1',
  },
  stepParent2: {
    path: '/application/step-parent-2',
    name: 'Step-Parent 2',
    component: StepParent,
    permission: 'stepParent2',
  },
  legalGuardian: {
    path: '/application/legal-guardian',
    name: 'Legal Guardian',
    component: LegalGuardian,
    permission: 'legalGuardian',
  },
  siblings: {
    path: '/application/siblings',
    name: 'Siblings',
    component: Siblings,
    permission: 'siblings',
  },
  // Education
  enrolimentStatus: {
    path: '/application/enrollment-status',
    name: 'Enrollment Status',
    component: EnrolimentStatusPage,
  },
  highSchool: {
    path: '/application/high-school',
    name: 'High Schools',
    component: HighSchool,
  },
  collegeAndUniversity: {
    path: '/application/college-and-university',
    name: 'Colleges & Universities',
    component: CollegeAndUniversity,
  },
  futurePlan: {
    path: '/application/future-plans',
    name: 'Future Plans',
    component: FuturePlan,
  },
  // Test
  generalTest: {
    path: '/application/general-info',
    name: 'General Info',
    component: GeneralInfoTest,
  },
  satTest: {
    path: '/application/sat-test',
    name: 'SAT Tests',
    component: SATTest,
    permission: 'satTest',
  },
  satSubjectTest: {
    path: '/application/sat-subject-test',
    name: 'SAT Subject Tests',
    component: SATSubjectTest,
    permission: 'satSubjectTest',
  },
  actTest: {
    path: '/application/act-test',
    name: 'ACT Tests',
    component: ACTTest,
    permission: 'actTest',
  },
  actSubjectTest: {
    path: '/application/act-subject-test',
    name: 'AP Subject Tests',
    component: ACTSubjectTest,
    permission: 'actSubjectTest',
  },
  ibSubjectTest: {
    path: '/application/ib-subject-test',
    name: 'IB Subject Tests',
    component: IBSubjectTest,
    permission: 'ibSubjectTest',
  },
  ieltsAndPte: {
    path: '/application/IELTS-PTE',
    name: 'IELTS & PTE',
    component: IELTSAndPTE,
    permission: 'ieltsAndPte',
  },
  toeflTest: {
    path: '/application/TOEFL-test',
    name: 'TOEFL Tests',
    component: TOEFL,
    permission: 'toeflTest',
  },
  douLingoTest: {
    path: '/application/Duo-Lingo-test',
    name: 'Duolingo Tests',
    component: DuoLingoTest,
    permission: 'douLingoTest',
  },
  clepTest: {
    path: '/application/CLEP-test',
    name: 'CLEP Tests',
    component: CLEP,
    permission: 'clepTest',
  },
  otherTest: {
    path: '/application/other-test',
    name: 'Others',
    component: OtherTest,
    // permission: 'otherTest',
  },
  // Courses And Grades
  generalCourse: {
    path: '/application/general-course-and-grade',
    name: 'General Info',
    component: GeneralInfo,
  },
  grade9: {
    path: '/application/9th-grade',
    name: '9th Grade',
    component: NinthGrade,
  },
  grade10: {
    path: '/application/10th-grade',
    name: '10th Grade',
    component: TenthGrade,
  },
  grade11: {
    path: '/application/11th-grade',
    name: '11th Grade',
    component: EleventhGrade,
  },
  grade12: {
    path: '/application/12th-grade',
    name: '12th Grade',
    component: TwelvethGrade,
  },
  collegeCourse: {
    path: '/application/college-course',
    name: 'College Courses',
    component: CollegeCourse,
  },
  // Activities and Honors
  activities: {
    path: '/application/activities',
    name: 'Activities',
    component: Activities,
  },
  honors: {
    path: '/application/honors',
    name: 'Honors',
    component: Honors,
  },
  educationalPrograms: {
    path: '/application/educational-programs',
    name: 'EOP and Other Info',
    component: EducationalPrograms,
  },

  // Additional Info
  additionalInfo: {
    path: '/application/additional-info',
    name: 'Additional Info',
    component: AdditionalInfo,
  },
  gpaForecaster: {
    path: '/application/gpa-forecaster',
    name: 'GPA Forecaster',
    component: GpaForecaster,
  },
  // Summary
  personalSum: {
    path: '/application/summary/personal/:id',
    name: 'Personal',
    component: RPersonal,
  },
  familySum: {
    path: '/application/summary/family/:id',
    name: 'Family',
    component: ReviewFamily,
  },
  educationSum: {
    path: '/application/summary/education/:id',
    name: 'Education',
    component: ReviewEducation,
  },
  testSum: {
    path: '/application/summary/test/:id',
    name: 'Tests',
    component: ReviewTest,
  },
  courseAndGradeSum: {
    path: '/application/summary/course-and-grade/:id',
    name: 'Courses & Grades',
    component: ReviewCourse,
  },
  activityAndHonorSum: {
    path: '/application/summary/activities-and-honors/:id',
    name: 'Acitivities & Honors',
    component: ReviewActivityAndHonor,
  },
  additionalSum: {
    path: '/application/summary/additional/:id',
    name: 'Additional Info',
    component: ReviewAdditionalInfo,
  },
  GPAForecasterSum: {
    path: '/application/summary/GPAForecasterSum/:id',
    name: 'GPA Forecaster',
    component: ReviewGPAForecaster,
  },
  onTest: {
    path: '/application/test',
    component: Test,
  },
  viewStudent: {
    path: '/application/counselor/:id',
    component: ReviewStudent,
  },
};

export const routers: any = {
  ...userRouters,
  ...appRoutes,
};

export const icons: any = {
  SchoolOutlinedIcon,
  PersonOutlineOutlinedIcon,
  PeopleAltOutlinedIcon,
  StickyNote2OutlinedIcon,
  CalculateOutlinedIcon,
  StackedLineChartIcon,
  ImportContactsTwoToneIcon,
  EventNoteOutlinedIcon,
  NoteAddOutlinedIcon,
  QueryStatsIcon,
};

export const appMenuGroups: any = [
  {
    name: 'Colleges',
    isGroup: true,
    icon: 'SchoolOutlinedIcon',
    children: [
      // routers.selectCollege,
      routers.collegeSearch,
      routers.myCollege,
    ],
  },
  {
    name: 'Personal',
    isGroup: true,
    icon: 'PersonOutlineOutlinedIcon',

    children: [
      routers.basicInformation,
      routers.contactDetail,
      routers.demographics,
      routers.citizenship,
      routers.cbo,
      routers.otherInformation,
    ],
  },
  {
    name: 'Family',
    icon: 'PeopleAltOutlinedIcon',
    isGroup: true,
    children: [
      routers.household,
      routers.parentNo1,
      routers.parentNo2,
      routers.legalGuardian,
      routers.stepParent1,
      routers.stepParent2,
      routers.siblings,
    ],
  },
  {
    name: 'Education',
    icon: 'StickyNote2OutlinedIcon',
    isGroup: true,
    children: [
      routers.enrolimentStatus,
      routers.highSchool,
      routers.collegeAndUniversity,
      routers.futurePlan,
    ],
  },
  {
    name: 'Courses & Grades',
    isGroup: true,
    icon: 'StackedLineChartIcon',
    children: [
      routers.generalCourse,
      routers.grade9,
      routers.grade10,
      routers.grade11,
      routers.grade12,
      routers.collegeCourse,
    ],
  },
  {
    name: 'Tests',
    icon: 'CalculateOutlinedIcon',
    isGroup: true,
    children: [
      routers.generalTest,
      routers.satTest,
      // routers.satSubjectTest ,
      routers.actTest,
      routers.actSubjectTest,
      routers.ibSubjectTest,
      routers.ieltsAndPte,
      routers.toeflTest,
      routers.douLingoTest,
      routers.clepTest,
      // routers.otherTest,
    ],
  },

  {
    name: 'Activities & Honors',
    icon: 'ImportContactsTwoToneIcon',
    isGroup: true,
    children: [routers.activities, routers.honors, routers.educationalPrograms],
  },
  {
    name: 'Additional Info',
    icon: 'EventNoteOutlinedIcon',
    isGroup: false,
    route: routers.additionalInfo,
    children: [],
  },
  {
    name: 'GPA Forecaster',
    icon: 'QueryStatsIcon',
    isGroup: false,
    route: routers.gpaForecaster,
    children: [],
  },
  {
    icon: 'NoteAddOutlinedIcon',
    name: 'Summary',
    isGroup: true,
    children: [
      routers.personalSum,
      routers.familySum,
      routers.educationSum,
      routers.courseAndGradeSum,
      routers.testSum,
      routers.activityAndHonorSum,
      routers.additionalSum,
      routers.GPAForecasterSum,
    ],
  },
];

export const counselorMenuGroups: any = [
  {
    name: '2023',
    isGroup: true,
    children: [],
  },
];
