/* eslint-disable react-hooks/exhaustive-deps */
import { emailRegex, phoneRegExp } from 'components/helper/appConfig';
import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface Props {
  hidden?: boolean;
  mdCol?: string | number;
  label?: string;
  name: string;
  values: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  disabled?: boolean;
  countText?: number;
  type?: string;
  min?: number;
  max?: number;
  className?: string;
  decimalStep?: number;
  form?: any;
  logo?: string;
  otherProps?: any;
  isOverCount?: boolean;
  countWord?: number;
  readOnly?: boolean;
  setLabelHight?: boolean;
}

const TextInput = (props: Props) => {
  const [counter, setCounter] = React.useState(0);
  const [phoneValidation, setPhoneValidation] = React.useState<string | undefined>();
  const [counterWord, setCounterWord] = React.useState(0);

  const getInfo = () => {
    const wordCount = ((props?.type === 'string' && props.values) || '')
      .split(/\s+/)
      .filter(Boolean).length;
    if (props.values?.length && props.countText) {
      setCounter(props.values.length ?? 0);
    } else if (props.countWord) {
      setCounterWord(wordCount ?? 0);
    }
  };

  React.useEffect(() => {
    if (props.type === 'phoneNumber' && !phoneRegExp.test(props.values)) {
      setPhoneValidation('Phone number is not valid');
    } else if (props.type === 'email' && !emailRegex.test(props.values)) {
      setPhoneValidation('Email is not valid');
    } else {
      setPhoneValidation(undefined);
    }
    getInfo();
  }, [props.values]);

  const handleChange = async (e: any) => {
    if (!props.isOverCount && props.countText && e.target.value.length > props.countText) {
      return;
    }

    const wordCount = (e.target.value || '').split(/\s+/).filter(Boolean).length;
    if (!props.isOverCount && props.countWord && wordCount > props.countWord) return;

    // prevent add more space
    if (!props.isOverCount && props.countWord && wordCount === props.countWord) {
      if (e.target.value[e.target.value.length - 1] === ' ') return;
    }

    props.onChange && props.onChange(e);
    setCounter(e.target.value.length);
  };

  return props.hidden ? null : (
    <Form.Group
      name={props.name}
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      as={Col}
      md={props.mdCol || '6'}
    >
      <div
        style={
          props.setLabelHight
            ? {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                minHeight: '40px',
              }
            : {}
        }
      >
        <Form.Label
          className={`label-14px ${props.logo ?? ''}`.trim()}
          style={
            props.setLabelHight
              ? {
                  margin: 0,
                  marginBottom: '5px',
                }
              : {}
          }
        >
          {props.label}
        </Form.Label>
      </div>
      <Form.Control
        id={`${props.name}-ID`}
        {...props.otherProps}
        step={props.decimalStep ?? 1}
        min={props.min || 0}
        max={props.max}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type || 'text'}
        name={props.name}
        value={props.values ?? ''}
        maxLength={props.isOverCount ? undefined : props.countText}
        onChange={handleChange}
        isValid={props.isValid}
        onWheel={(e) => e.currentTarget.blur()}
        readOnly={props.readOnly}
      />

      {(props.feedback ||
        phoneValidation ||
        (!!props.max && props.type === 'number' && props.values > props.max) ||
        (!!props.min && props.type === 'number' && props.values < props.min) ||
        (props.countText && props.type !== 'number') ||
        (props.countWord && props.type !== 'number')) && (
        <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
          {props.feedback && <span>{props.feedback}</span>}
          {phoneValidation && <span className="text-danger">{phoneValidation}</span>}
          {!!props.max && props.type === 'number' && props.values > props.max && (
            <span className="text-danger">Invalid number</span>
          )}
          {!!props.min && props.type === 'number' && props.values < props.min && (
            <span className="text-danger">Invalid number</span>
          )}
          {props.countText && props.type !== 'number' && (
            <span title={`${counter}/${props.countText} characters`}>
              {counter}/{props.countText} characters
            </span>
          )}
          {props.countWord && props.type !== 'number' && (
            <span title={`${counterWord}/${props.countWord} words`}>
              {counterWord}/{props.countWord} words
            </span>
          )}
        </div>
      )}
    </Form.Group>
  );
};

export default TextInput;
