import http from './http';
import toast from 'react-hot-toast';

const gpaForecastDetailServices = {
  async getGpaForeCastDetails(gpa_forecast_id: any) {
   const body = {
    gpa_forecast_id: gpa_forecast_id
   };
    const res:any[] = await http.post(`api/gpa-forecast-detail/search/by_gpa_forecast_id`, body);  
    return res;
  },

  async createGpaForeCastDetail(body: any): Promise<any> {
    const res = await http.post('api/gpa-forecast-detail/', body);
    return res;
  },


  async updateGpaForeCastDetail(body: any): Promise<any> {
    const res = await http.put('api/gpa-forecast-detail', body);
    return res;
  },

  async deleteGpaForeCastDetail(id: any) {
    const res: any = await http.delete(`api/gpa-forecast-detail/${id}`);
    if (res) {
      toast.success('Detete Record successull.');
    }
    return res;
  },


};

export default gpaForecastDetailServices;
