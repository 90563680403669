// import { styled } from '@mui/system';
// import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Form, Spinner } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

interface Props {
  hidden?: boolean;
  borderless?: boolean;
  label?: string;
  option: { id: number; label: any; value: any; code: string }[];
  mdCol?: string;
  name: string;
  values?: any;
  onChangeItem?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  defautlValues?: any;
  disabled?: boolean;
  placeholder?: string;
  form: any;
  className?: string;
  logo?: string;
  onSearch: (keyword: string) => void;
  iconClass?: string;
  icon?: any;
  inputRef?: any;
  isLoading?: boolean;
  emptyLabel?: any;
}

const SelectSearchInput = (props: Props) => {
  let filterByFields = ['label', 'code'];

  //  fix filterBy warning
  if (props.option && props.option.length > 0) {
    if (
      props.option.hasOwnProperty('label') &&
      props.option.hasOwnProperty('value') &&
      props.option.hasOwnProperty('code')
    ) {
      filterByFields = ['label', 'value', 'code'];
    }
  }

  return props.hidden ? null : (
    <Form.Group
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      name={props.name}
      as={Col}
      md={props.mdCol || '6'}
      controlId={`${props.name}_ID`}
    >
      <Form.Label style={{ fontSize: '1rem', color: '#4E5A6F' }} className={props.logo}>
        {props.label}
      </Form.Label>
      <div className={`${props.iconClass ?? 'text-field-light'} icon-search-prefix `}>
        {props.icon ? props.icon : <SearchIcon />}
      </div>
      <Typeahead
        id={props.name}
        onInputChange={debounce((e) => props.onSearch(e), 500)}
        onChange={(selected: any) => {
          try {
            props.onChangeItem && props.onChangeItem(selected[0]);

            if (selected[0]?.value) {
              props.form.setFieldValue(props.name, selected[0].value);
            }
          } catch (ex) {
            // skip
          }
        }}
        emptyLabel={
          props.emptyLabel ? (
            props.emptyLabel
          ) : (
            <>
              {props.option?.length === 0 && !!props.values.length && (
                <Stack alignItems={'center'}>
                  {props.option?.length === 0 && !!props.values.length ? (
                    <>
                      <Typography sx={{ color: '#0c2551' }}>Not found</Typography>
                    </>
                  ) : (
                    <>{props.isLoading && <Spinner color="primary" animation="border" />}</>
                  )}
                </Stack>
              )}
            </>
          )
        }
        labelKey="label"
        defaultSelected={props.values ? [props.values] : undefined}
        options={props.option}
        filterBy={filterByFields}
        ref={props.inputRef}
        isLoading={props.isLoading}
      />
      {props.feedback && (
        <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
          {props.feedback}
        </div>
      )}
      <style scoped>{`
        .icon-search-prefix {
          position: absolute;
          z-index: 1000;
          padding-top: 16px;
          padding-left: 8px;
        }
        .rbt > div > input {
          padding-left: 32px;
        }
        .rbt-aux {
          position: absolute;
          top:16px;
          left:9px;
        }
      `}</style>
    </Form.Group>
  );
};

export default SelectSearchInput;
