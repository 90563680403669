/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import TextAreaInput from 'components/input/TextAreaInput';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import {
  getAdditionalInfo,
  updateAdditionalInfo,
} from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { debounce } from 'lodash';
import YesNoSwitch from 'components/input/YesNoSwitch';

interface Props {}

const AdditionalInfo = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const activitiesAndHonors = useAppSelector((store) => store.activitiesAndHonors);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      additional_comment_1: '',
      additional_comment_2: '',
      additional_comment_3: '',
      wish_to_provide_detail_of_circumstances_option: '',
      wish_to_share_option: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
      };
      await dispatch(updateAdditionalInfo(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.gpaForecaster.path.replace(':id', studentId));
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      const res = await dispatch(getAdditionalInfo(studentId));
      const dataLoaded = res.payload;
      if (dataLoaded) {
        formik.setValues({ ...formik.values, ...dataLoaded });
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Aditional Information"
        process={1}
        icon={<EventNoteOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Additional Information
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    If there is anything else you'd like to colleges to consider beyond what you've
                    already included in your application, include them here. Please see our FAQs for
                    things to consider including.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">Additional Information</div>
                <div>
                  Community disruptions such as COVID-19 and natural disasters can have deep and
                  long-lasting impacts. If you need it, this space is yours to describe those
                  impacts. Colleges care about the effects on your health and well-being, safety,
                  family circumstances, future plans, and education, including access to reliable
                  technology and quiet study spaces. For more information, check out our COVID-19
                  FAQ
                </div>

                <YesNoSwitch
                  className="mb-3"
                  name="wish_to_share_option"
                  onChange={formik.handleChange}
                  values={formik.values.wish_to_share_option}
                  label="Do you wish to share anything on this topic?*"
                  onClear={() => formik.setFieldValue('wish_to_share_option', '')}
                />
                {formik.values.wish_to_share_option === 'true' && (
                  <TextAreaInput
                    className="common-flag"
                    mdCol="12"
                    label="Please use this space to describe how these events have impacted you.*"
                    name="additional_comment_3"
                    values={formik.values.additional_comment_3}
                    onChange={formik.handleChange}
                    countWord={250}
                    isOverCount={true}
                    rows={4}
                  />
                )}
                <YesNoSwitch
                  className="mb-3"
                  name="wish_to_provide_detail_of_circumstances_option"
                  onChange={formik.handleChange}
                  values={formik.values.wish_to_provide_detail_of_circumstances_option}
                  label="Do you wish to provide details of circumstances or qualifications not reflected in the application?*"
                  onClear={() =>
                    formik.setFieldValue('wish_to_provide_detail_of_circumstances_option', '')
                  }
                />
                {formik.values.wish_to_provide_detail_of_circumstances_option === 'true' && (
                  <TextAreaInput
                    logo="span-cm-logo"
                    mdCol="12"
                    label="Additional Information"
                    name="additional_comment_1"
                    values={formik.values.additional_comment_1}
                    onChange={formik.handleChange}
                    countWord={650}
                    isOverCount={true}
                    rows={4}
                    placeholder="Ex: 1st generation college student, changed high school, special circumstances, family issues, international student, if you were born outside of the U.S."
                  />
                )}
                <TextAreaInput
                  logo="span-uc-logo"
                  mdCol="12"
                  label="Additional Comments"
                  name="additional_comment_2"
                  values={formik.values.additional_comment_2}
                  onChange={formik.handleChange}
                  countWord={550}
                  isOverCount={true}
                  rows={4}
                  placeholder="Ex: 1st generation college student, changed high school, special circumstances, family issues, international student, if you were born outside of the U.S."
                />
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {activitiesAndHonors.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default AdditionalInfo;
