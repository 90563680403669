import { checkEnableParseJSON } from 'components/helper/helper';
import { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ModalCheckFieldProps {
  show?: boolean;
  onClose: () => void;
  data: any;
}

const baseRequiredFields = [
  {
    key: 'csu_info.emailType',
    name: 'Email Address Type',
  },
  {
    key: 'first_name',
    name: 'First/given name',
  },
  {
    key: 'last_name',
    name: 'Last/family/surname',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'dob',
    name: 'Date of birth',
  },
  {
    key: 'country',
    name: 'Country',
  },
  {
    key: 'city',
    name: 'City',
  },
  {
    key: 'csu_info.have_lived_name',
    name: 'Do you have a lived /preferred name?',
  },
  {
    key: 'csu_info.legal_sex',
    name: 'Legal sex',
  },
  {
    key: 'sex_self_describe',
    name: 'How do you describe yourself?',
  },
  {
    key: 'sex_self_consider',
    name: 'What do you consider yourself to be?',
  },
  {
    key: 'csu_info.gender_identity',
    name: 'How do you describe the way you express your gender identity in terms of behavior, appearance, speech, and movement?',
  },
];

export const ModalCheckField = (props: ModalCheckFieldProps) => {
  const { show, onClose, data } = props;
  const [filedsFail, setFieldsFail] = useState<any[]>([]);

  const requiredFields = useMemo(() => {
    let fields = [...baseRequiredFields];

    if (data?.csu_info?.have_lived_name === 'true' || data?.csu_info.have_lived_name === true) {
      fields.push(
        {
          key: 'csu_info.lived_first_name',
          name: 'Lived first name',
        },
        {
          key: 'csu_info.lived_last_name',
          name: 'Lived Last / family / surname',
        }
      );
    }

    if (data?.is_have_legal_name === 'true' || data.is_have_legal_name === true) {
      fields.push(
        {
          key: 'legal_first_name',
          name: 'Former First Name',
        },
        {
          key: 'legal_family_name',
          name: 'Former Last Name',
        }
      );
    }

    if (data?.sex) {
      // const parsedSex = checkEnableParseJSON(data?.sex)
      if (data?.sex?.isAnother) {
        fields.push({
          key: 'sex.anotherGender',
          name: 'My gender',
        });
      }
    }

    if (data?.sex_self_describe === 'identity') {
      fields.push({
        key: 'csu_info.another_identity',
        name: 'Another identity (please specify)',
      });
    }

    if (data?.csu_info?.gender_identity === 'None of the Above') {
      fields.push({
        key: 'csu_info.another_gender_identity',
        name: 'Another identity expression (please specify)',
      });
    }

    return fields;
  }, [data]);

  const checkRequiredFields = (data: any) => {
    const result: any = [];

    requiredFields.forEach((field) => {
      if (!field.key.includes('.')) {
        if (!data[field.key]) {
          result.push({ name: field.name });
        }
      } else {
        const fieldArray = field.key.split('.');
        let temp = data;
        fieldArray.forEach((item) => {
          if (temp && temp[item]) {
            temp = temp[item];
          } else {
            result.push({ name: field.name });
          }
        });
      }
    });
    setFieldsFail(result);
  };

  useEffect(() => {
    checkRequiredFields(data);
  }, [data, requiredFields]);

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {data && filedsFail.length > 0 ? (
          <div>
            <h3>These fields are not yet valid, please complete them: </h3>
            <ul style={{ fontSize: '1rem', color: 'red' }}>
              {filedsFail.map((field, index) => (
                <li key={index}>{field.name}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3>All required fields are filled</h3>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
