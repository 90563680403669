/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListSubheader } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ReviewStudent from 'screen/counselor/ReviewStudent';
import { checkEnableParseJSON, removeDuplicate } from 'components/helper/helper';
import { counselorMenuGroups } from 'router/router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authenticateSettings } from 'components/helper/appConfig';
import { checkModule, logout } from 'app/basicInformationSlice/basicInformationSlice';
import { routers } from 'router/router';
import Cookies from 'js-cookie';
import testingServices from 'services/testingServices';
import familyServices from 'services/familyServices';

type Props = {
};

const CounselorListMenu = (props: Props) => {
  const [open, setOpen] = useState<string | null>('2024');
  let history = useHistory();   
  const menu = useAppSelector((store) => store.counselor.studentList);
  const parentLists = useAppSelector((store) => store.basicInformation.partners);
  const [menuGroups, setMenuGroups] = useState(counselorMenuGroups);
  const[studentId, setStudentId] = useState<Number|undefined>(undefined);

  const handleClick = (route: any) => {
    if (open === route.name) return setOpen(null);
    setOpen(route.name);
    if (route.isGroup === false) {
      history.push(route.route.path);
    }
  };  

  
  useEffect(() => {
    if (menu?.length) {
      let menuRebuild = menu
        .filter((i) => i.student_info)
        .map((item) => ({
          name: [item.student_info?.first_name, item.student_info?.last_name].join(' '),
          id: item.student_id,
          groupYear: item?.student_info?.year_finish_high_school,
          component: ReviewStudent,
          path: `/application/counselor/${item.student_id}`,
        }))
        .sort((a: any, b: any) => {
          const aLower: string = a.name.toLowerCase();
          const bLower: string = b.name.toLowerCase();
          if (aLower < bLower) return -1;
          if (aLower > bLower) return 1;
          return 0;
        })
        .sort((a, b) => b.groupYear - a.groupYear);

      let menuGroup = removeDuplicate(menuRebuild.map((e: any) => e.groupYear)).map((menuItem) => ({
        name: menuItem,
        isGroup: true,
        children: menuRebuild.filter((e: any) => e.groupYear === menuItem),
      }));
      setMenuGroups(menuGroup);
      menuGroup[0].name && setOpen(menuGroup[0].name);
    }
  }, [menu]);
 
  useEffect(() => {
    const genMenuInfo = async () => {
      try {
        if (menuGroups && parentLists) {
          let menuArr =  menuGroups;
          for(let i=0;i< menuArr.length; i++) {
            let item = menuArr[i];
            if (item.children) {
              for(let j=0; j< item.children.length; j++) {
                let child = item.children[j];
                for (let k=0; k< parentLists.length; k++) {
                  let obj = parentLists[k];
                  if (child.id === obj.student_id) {
                    child.info = obj;
                  }
                }
              }
            }
          }
  
          setMenuGroups(menuArr);
        }
      }
      catch(ex){
        console.log('Error: ', ex);
      }      
    }
    genMenuInfo();
  }, [parentLists]);

  const onClickItem = async (child:any) => {
    setStudentId(child?.id);    
    history.push(child?.path);    
  }

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        subheader={
          <ListSubheader component="div" className="fw-bold text-dark fs-5">
            <PeopleAltOutlinedIcon className="mx-1 pb-1" />
            Students
          </ListSubheader>
        }
      >
        {menuGroups.map((route: any, index: any) => {
          return (
            <div key={index}>
              <ListItemButton
                onClick={() => handleClick(route)}
                className={`px-1 my-1 ${open === route.name && 'bg-light'}`}
              >
                <span className="text-dark fw-5 mx-2 fw-bold">{route.name}</span>
                {route.isGroup &&
                  (open === route.name ? (
                    <ExpandLess fontSize="small" className="text-field-light" />
                  ) : (
                    <ExpandMore fontSize="small" className="text-field-light" />
                  ))}
              </ListItemButton>
              {route.isGroup && (
                <Collapse in={open === route.name} timeout="auto" unmountOnExit>
                  <div style={{ height: '100%', overflowY: 'auto' }}>
                    <List component="div" disablePadding>
                      {route.children.map((child: any, i: number) => {
                        return (
                        <CounselorSubMenu child={child} index={i} 
                        //parentLists={parentLists} 
                        studentId={studentId} onClickItem={onClickItem}/>
                        );
                      })}
                    </List>
                  </div>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </div>
  );
};

type CounselorSubMenuProps = {
  studentId : any;
  child: any;
  index: number;
  //parentLists: any;
  onClickItem?: (child: any) => void;
};
const CounselorSubMenu = (props: CounselorSubMenuProps) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  let location = useLocation();  
  const[roleFamily, setRoleFamily] = useState<any>({});
  const[roleTest, setRoleTest] = useState<any>({});
  //const myHousehold = useAppSelector((store) => store.family.myHousehold);
  //const myGeneral = useAppSelector((store) => store.testing.myGeneral);
  const partnerLists = useAppSelector((store) => store.basicInformation.partners);
  let token = Cookies.get(authenticateSettings.tokenName);

  const obj = partnerLists && partnerLists.find && partnerLists.find((item: any) => Number(item.student_id) === Number(props.child.id));
  if (obj) {
    props.child.info = obj; // update info
  }

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  useEffect(() => {
    const syncRoleFamily = async () => {
      if (obj?.student_id) {
        const role = await getRoleFamily(obj?.student_id)
        setRoleFamily(role);
      }
    }
    syncRoleFamily();

  }, [obj]);

  useEffect(() => {
    const syncRoleTest= async () => {
      if (obj?.student_id) {
        const role = await getRoleTest(obj?.student_id);
        setRoleTest(role);
      }
    }
    syncRoleTest();

  }, [obj]);


  const buildRoleTest = (general:any) => {
    const all_tests_wish_report_array:any = checkEnableParseJSON(
      general?.all_tests_wish_report_array ?? {}
    );
    return {
      satTest: all_tests_wish_report_array?.is_SAT,
      actTest: all_tests_wish_report_array?.is_ACT,
      actSubjectTest: all_tests_wish_report_array?.is_AP_Subject,
      ibSubjectTest: all_tests_wish_report_array?.is_IB_Subject,
      ieltsAndPte: all_tests_wish_report_array?.is_PTE || all_tests_wish_report_array?.is_IELTS,
      toeflTest: all_tests_wish_report_array?.is_TOEFL_Paper || all_tests_wish_report_array?.is_TOEFL,
      douLingoTest: all_tests_wish_report_array?.is_DuoLingo,
      clepTest: all_tests_wish_report_array?.is_CLEP,
    };
  }

  async function getRoleTest(studentId:any) {
    let role:any = {
      satTest: false,
      actTest: false,
      actSubjectTest: false,
      ibSubjectTest: false,
      ieltsAndPte: false,
      toeflTest: false,
      douLingoTest: false,
      clepTest: false,
    };
    try {
      token = Cookies.get(authenticateSettings.tokenName);
      if (token) {
        const general:any = await testingServices.getGeneral(studentId);
        //const general:any = await dispatch(getGeneral(studentId)).unwrap();
        role = buildRoleTest(general);        
      }
      else {
        handleLogout();
      }

    }
    catch(ex){
      console.log('Error: ', ex);
    }   
    return role;
  }
   
  const buildRoleFamily = (myHousehold: any) => {
    const who_in_household_array = checkEnableParseJSON(myHousehold?.who_in_household_array, {});
    let role = {
      parentNo1: false,
      parentNo2: false,
      legalGuardian: false,
      stepParent1: false,
      stepParent2: false,
      siblings: false,
    };
    if (who_in_household_array?.is_parent_guardian) {
      role.parentNo1 = true;
      role.parentNo2 = true;
    }
    if (who_in_household_array?.is_sibbling) {
      role.siblings = true;
    }
  
    if (
      myHousehold?.is_listing_step_parents === 'true' &&
      myHousehold?.how_many_step_parents === '1'
    ) {
      role.stepParent1 = true;
    } else if (myHousehold?.is_listing_step_parents === 'true') {
      role.stepParent1 = true;
      role.stepParent2 = true;
    }
    if (
      myHousehold?.whom_live_permanently === 'Legal Guardian' ||
      myHousehold?.whom_live_permanently === 'Ward of Court/State'
    ) {
      role.legalGuardian = true;
    }
  
    return role;
  };    
  
  async function getRoleFamily(studentId:any) {
    let role:any = {
      parentNo1: false,
      parentNo2: false,
      legalGuardian: false,
      stepParent1: false,
      stepParent2: false,
      siblings: false,
    };
    try {
      token = Cookies.get(authenticateSettings.tokenName);
      if (token) {
        const myHousehold:any = await familyServices.getHousehold(studentId);
        role = buildRoleFamily(myHousehold);
      }
      else {
        handleLogout();
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }
    return role;
  }  

  function filteredKeys_removeItem(filteredKeys:string[], keyVal:string) {
    var index = filteredKeys.findIndex((e:string) => e === keyVal);
    if (index>-1) {
      filteredKeys.splice(index, 1);    
    }
  }

  function filteredKeys_checkRoleTest(filteredKeys:string[], roleTest:any) {
      if (roleTest) {
        if (roleTest?.satTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.1');   // SAT Tests
        }
        if (roleTest?.actTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.2');   // ACT Tests
        }
        if (roleTest?.actSubjectTest === false) {      
          filteredKeys_removeItem(filteredKeys, '4.3');   // AP Subject Tests
        }
        if (roleTest?.ibSubjectTest === false) {       
          filteredKeys_removeItem(filteredKeys, '4.4');   // IB Subject Tests
        }
        if (roleTest?.clepTest === false) {         
          filteredKeys_removeItem(filteredKeys, '4.5');   // CLEP Tests 
        }
        if (roleTest?.toeflTest === false) {           
          filteredKeys_removeItem(filteredKeys, '4.6');   //  TOEFL iBT
        }        
        if (roleTest?.ieltsAndPte === false) {
          filteredKeys_removeItem(filteredKeys, '4.7');   //  PTE Academic && IELTS
        }
        if (roleTest?.douLingoTest === false) {
          filteredKeys_removeItem(filteredKeys, '4.8');   //  DuoLingo
        }
      }
  }  

  function filteredKeys_checkRoleFamily(filteredKeys:string[], roleFamily:any) {
    if (roleFamily) {
      if (roleFamily?.parentNo1 === false ) {
        filteredKeys_removeItem(filteredKeys, '2.2');     // Parent/Guardian 1
      }
      if (roleFamily?.parentNo2 === false ) {
        filteredKeys_removeItem(filteredKeys, '2.3');     // Parent/Guardian 2
      }
      if (roleFamily?.stepParent1 === false || roleFamily?.stepParent2 === false) {
        filteredKeys_removeItem(filteredKeys, '2.5');     // Step-Parent(s)
      }
      if (roleFamily?.siblings === false ) {
        filteredKeys_removeItem(filteredKeys, '2.4');     // Sibling(s)
      }
    }    
  }
    

  let loadedCheckModule:any = checkEnableParseJSON(obj?.notes_array, {
    checkModule,
    notes: [],
  })?.checkModule ?? checkModule;
  
  if (loadedCheckModule === null) {
    console.log('loadedCheckModule is null');
    loadedCheckModule = {...checkModule};
  }

  let filteredKeys = loadedCheckModule ? Object.keys(loadedCheckModule).filter(key => {
    const [first, second] = key.split('.').map(Number);
    return ((first >= 1 && first <= 7) || first===9) && second >= 1 && second <= 6;
  }) : [] 

  filteredKeys.sort();

  filteredKeys_checkRoleTest(filteredKeys,roleTest);
  filteredKeys_checkRoleFamily(filteredKeys, roleFamily);
  const allTrue = filteredKeys.length > 0 && filteredKeys.every((key: any) => loadedCheckModule[key][0] === true);
  const allCounselorTrue = filteredKeys.length > 0 && filteredKeys.every((key: any) => loadedCheckModule[key][1] === true);
  return (
    <ListItemButton
      key={props.index}
      sx={{ pl: 2, pt: 2 }}
      onClick={() => {
        if (props.onClickItem) {
          props.onClickItem(props.child);
        }
      }}
    >
      <span className="text-secondary">{props.child?.icon}</span>
      <span
        className={
          location.pathname === props.child?.path
            ? 'text-primary fw-bold'
            : 'text-dark'
        }
      >
        {props.child?.name}
      </span>
      <span className="ms-auto">
        {allTrue && (
          <img
            alt="csu"
            src="./assets/icons/tick-blue.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {allCounselorTrue && (
          <img
            alt="csu"
            src="./assets/icons/tick-green.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
      </span>
    </ListItemButton>
  );  
}

export default CounselorListMenu;
