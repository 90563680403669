/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { authenticateSettings } from 'components/helper/appConfig';
import { removeIntercomeCache } from 'components/helper/helper';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import basicInformationServices from 'services/basicInformationApi';
import crypto from 'crypto';

export const checkModule: any = {
  '1.1': [false, false],
  '1.2': [false, false],
  '1.3': [false, false],
  '1.4': [false, false],
  '1.5': [false, false],
  '2.1': [false, false],
  '2.2': [false, false],
  '2.3': [false, false],
  '2.4': [false, false],
  '3.1': [false, false],
  '3.2': [false, false],
  '3.3': [false, false],
  '4.1': [false, false],
  '4.2': [false, false],
  '4.3': [false, false],
  '4.4': [false, false],
  '4.5': [false, false],
  '5.1': [false, false],
  '5.2': [false, false],
  '5.3': [false, false],
  '5.4': [false, false],
  '5.5': [false, false],
  '5.6': [false, false],
  '5.7': [false, false],
  '5.8': [false, false],
  '6.1': [false, false],
  '6.2': [false, false],
  '6.3': [false, false],
  '6.6': [false, false],
  '6.5': [false, false],
  '7.1': [false, false],
  '7.2': [false, false],
  '7.3': [false, false],
  '8.1': [false, false],
  '8.2': [false, false],
  '9.1': [false, false],
};

export type checkModuleType = {
  '1.1': boolean[];
  '1.2': boolean[];
  '1.3': boolean[];
  '1.4': boolean[];
  '1.5': boolean[];
  '2.1': boolean[];
  '2.2': boolean[];
  '2.3': boolean[];
  '2.4': boolean[];
  '3.1': boolean[];
  '3.2': boolean[];
  '3.3': boolean[];
  '4.1': boolean[];
  '4.2': boolean[];
  '4.3': boolean[];
  '4.4': boolean[];
  '4.5': boolean[];
  '5.1': boolean[];
  '5.2': boolean[];
  '5.3': boolean[];
  '5.4': boolean[];
  '5.5': boolean[];
  '5.6': boolean[];
  '5.7': boolean[];
  '5.8': boolean[];
  '6.1': boolean[];
  '6.2': boolean[];
  '6.3': boolean[];
  '6.6': boolean[];
  '6.5': boolean[];
  '7.1': boolean[];
  '7.2': boolean[];
  '7.3': boolean[];
  '8.1': boolean[];
  '8.2': boolean[];
  '9.1': boolean[];
};

export const sanitizeCheckedModule = (loadCheckedModule: checkModuleType) => {
  const getModuleValue = (item: checkModuleType, key: string, index: number) => {
    try {
      switch (key) {
        case '1.1':
          return item[key][index] ?? false;
        case '1.2':
          return item[key][index] ?? false;
        case '1.3':
          return item[key][index] ?? false;
        case '1.4':
          return item[key][index] ?? false;
        case '1.5':
          return item[key][index] ?? false;
        case '2.1':
          return item[key][index] ?? false;
        case '2.2':
          return item[key][index] ?? false;
        case '2.3':
          return item[key][index] ?? false;
        case '2.4':
          return item[key][index] ?? false;
        case '3.1':
          return item[key][index] ?? false;
        case '3.2':
          return item[key][index] ?? false;
        case '3.3':
          return item[key][index] ?? false;
        case '4.1':
          return item[key][index] ?? false;
        case '4.2':
          return item[key][index] ?? false;
        case '4.3':
          return item[key][index] ?? false;
        case '4.4':
          return item[key][index] ?? false;
        case '4.5':
          return item[key][index] ?? false;
        case '5.1':
          return item[key][index] ?? false;
        case '5.2':
          return item[key][index] ?? false;
        case '5.3':
          return item[key][index] ?? false;
        case '5.4':
          return item[key][index] ?? false;
        case '5.5':
          return item[key][index] ?? false;
        case '5.6':
          return item[key][index] ?? false;
        case '5.7':
          return item[key][index] ?? false;
        case '5.8':
          return item[key][index] ?? false;
        case '6.1':
          return item[key][index] ?? false;
        case '6.2':
          return item[key][index] ?? false;
        case '6.3':
          return item[key][index] ?? false;
        case '6.6':
          return item[key][index] ?? false;
        case '6.5':
          return item[key][index] ?? false;
        case '7.1':
          return item[key][index] ?? false;
        case '7.2':
          return item[key][index] ?? false;
        case '7.3':
          return item[key][index] ?? false;
        case '8.1':
          return item[key][index] ?? false;
        case '8.2':
          return item[key][index] ?? false;
        case '9.1':
          return item[key][index] ?? false;
        default:
          return false;
      }
    }
    catch (ex) {
      return false;
    }
  }
  let ret = JSON.parse(JSON.stringify(checkModule));
  try {
    if (loadCheckedModule != null) {
      ret['1.1'][0] = getModuleValue(loadCheckedModule, '1.1', 0);
      ret['1.1'][1] = getModuleValue(loadCheckedModule, '1.1', 1);
      ret['1.2'][0] = getModuleValue(loadCheckedModule, '1.2', 0);
      ret['1.2'][1] = getModuleValue(loadCheckedModule, '1.2', 1);
      ret['1.3'][0] = getModuleValue(loadCheckedModule, '1.3', 0);
      ret['1.3'][1] = getModuleValue(loadCheckedModule, '1.3', 1);
      ret['1.4'][0] = getModuleValue(loadCheckedModule, '1.4', 0);
      ret['1.4'][1] = getModuleValue(loadCheckedModule, '1.4', 1);
      ret['1.5'][0] = getModuleValue(loadCheckedModule, '1.5', 0);
      ret['1.5'][1] = getModuleValue(loadCheckedModule, '1.5', 1);
      ret['2.1'][0] = getModuleValue(loadCheckedModule, '2.1', 0);
      ret['2.1'][1] = getModuleValue(loadCheckedModule, '2.1', 1);
      ret['2.2'][0] = getModuleValue(loadCheckedModule, '2.2', 0);
      ret['2.2'][1] = getModuleValue(loadCheckedModule, '2.2', 1);
      ret['2.3'][0] = getModuleValue(loadCheckedModule, '2.3', 0);
      ret['2.3'][1] = getModuleValue(loadCheckedModule, '2.3', 1);
      ret['2.4'][0] = getModuleValue(loadCheckedModule, '2.4', 0);
      ret['2.4'][1] = getModuleValue(loadCheckedModule, '2.4', 1);
      ret['3.1'][0] = getModuleValue(loadCheckedModule, '3.1', 0);
      ret['3.1'][1] = getModuleValue(loadCheckedModule, '3.1', 1);
      ret['3.2'][0] = getModuleValue(loadCheckedModule, '3.2', 0);
      ret['3.2'][1] = getModuleValue(loadCheckedModule, '3.2', 1);
      ret['3.3'][0] = getModuleValue(loadCheckedModule, '3.3', 0);
      ret['3.3'][1] = getModuleValue(loadCheckedModule, '3.3', 1);
      ret['4.1'][0] = getModuleValue(loadCheckedModule, '4.1', 0);
      ret['4.1'][1] = getModuleValue(loadCheckedModule, '4.1', 1);
      ret['4.2'][0] = getModuleValue(loadCheckedModule, '4.2', 0);
      ret['4.2'][1] = getModuleValue(loadCheckedModule, '4.2', 1);
      ret['4.3'][0] = getModuleValue(loadCheckedModule, '4.3', 0);
      ret['4.3'][1] = getModuleValue(loadCheckedModule, '4.3', 1);
      ret['4.4'][0] = getModuleValue(loadCheckedModule, '4.4', 0);
      ret['4.4'][1] = getModuleValue(loadCheckedModule, '4.4', 1);
      ret['4.5'][0] = getModuleValue(loadCheckedModule, '4.5', 0);
      ret['4.5'][1] = getModuleValue(loadCheckedModule, '4.5', 1);
      ret['5.1'][0] = getModuleValue(loadCheckedModule, '5.1', 0);
      ret['5.1'][1] = getModuleValue(loadCheckedModule, '5.1', 1);
      ret['5.2'][0] = getModuleValue(loadCheckedModule, '5.2', 0);
      ret['5.2'][1] = getModuleValue(loadCheckedModule, '5.2', 1);
      ret['5.3'][0] = getModuleValue(loadCheckedModule, '5.3', 0);
      ret['5.3'][1] = getModuleValue(loadCheckedModule, '5.3', 1);
      ret['5.4'][0] = getModuleValue(loadCheckedModule, '5.4', 0);
      ret['5.4'][1] = getModuleValue(loadCheckedModule, '5.4', 1);
      ret['5.5'][0] = getModuleValue(loadCheckedModule, '5.5', 0);
      ret['5.5'][1] = getModuleValue(loadCheckedModule, '5.5', 1);
      ret['5.6'][0] = getModuleValue(loadCheckedModule, '5.6', 0);
      ret['5.6'][1] = getModuleValue(loadCheckedModule, '5.6', 1);
      ret['5.7'][0] = getModuleValue(loadCheckedModule, '5.7', 0);
      ret['5.7'][1] = getModuleValue(loadCheckedModule, '5.7', 1);
      ret['5.8'][0] = getModuleValue(loadCheckedModule, '5.8', 0);
      ret['5.8'][1] = getModuleValue(loadCheckedModule, '5.8', 1);
      ret['6.1'][0] = getModuleValue(loadCheckedModule, '6.1', 0);
      ret['6.1'][1] = getModuleValue(loadCheckedModule, '6.1', 1);
      ret['6.2'][0] = getModuleValue(loadCheckedModule, '6.2', 0);
      ret['6.2'][1] = getModuleValue(loadCheckedModule, '6.2', 1);
      ret['6.3'][0] = getModuleValue(loadCheckedModule, '6.3', 0);
      ret['6.3'][1] = getModuleValue(loadCheckedModule, '6.3', 1);
      ret['6.5'][0] = getModuleValue(loadCheckedModule, '6.5', 0); // warning order
      ret['6.5'][1] = getModuleValue(loadCheckedModule, '6.5', 1); // warning order
      ret['6.6'][0] = getModuleValue(loadCheckedModule, '6.6', 0); // warning order
      ret['6.6'][1] = getModuleValue(loadCheckedModule, '6.6', 1); // warning order
      ret['7.1'][0] = getModuleValue(loadCheckedModule, '7.1', 0);
      ret['7.1'][1] = getModuleValue(loadCheckedModule, '7.1', 1);
      ret['7.2'][0] = getModuleValue(loadCheckedModule, '7.2', 0);
      ret['7.2'][1] = getModuleValue(loadCheckedModule, '7.2', 1);
      ret['7.3'][0] = getModuleValue(loadCheckedModule, '7.3', 0);
      ret['7.3'][1] = getModuleValue(loadCheckedModule, '7.3', 1);
      ret['8.1'][0] = getModuleValue(loadCheckedModule, '8.1', 0);
      ret['8.1'][1] = getModuleValue(loadCheckedModule, '8.1', 1);
      ret['8.2'][0] = getModuleValue(loadCheckedModule, '8.2', 0);
      ret['8.2'][1] = getModuleValue(loadCheckedModule, '8.2', 1);
      ret['9.1'][0] = getModuleValue(loadCheckedModule, '9.1', 0);
      ret['9.1'][1] = getModuleValue(loadCheckedModule, '9.1', 1);
    }
  }
  catch (ex) {
    console.log('Error: ', ex);
  }
  return ret;
}

export interface BasicInformationState {
  myStripeSubscription: any;
  stripeSubscriptionInfo: any;
  myBasicInformation: any;
  myContactDetail: any;
  myDemographics: any;
  myCitizenship: any;
  listInformation: any[];
  CBOList: any[];
  status: 'idle' | 'loading' | 'failed';
  checkModule: any;
  partners: any;
}

const initialState: BasicInformationState = {
  listInformation: [],
  stripeSubscriptionInfo: null,
  myStripeSubscription: null,
  myBasicInformation: null,
  myContactDetail: null,
  myDemographics: null,
  myCitizenship: null,
  checkModule: null,
  partners: null,
  CBOList: [],
  status: 'idle',
};
export const getStripeSubscriptionInfo = createAsyncThunk(
  'basicInformation/getStripeSubscriptionInfo',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getEnableExtention(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCitizenship = createAsyncThunk(
  'basicInformation/getCitizenship',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getCitizenship(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getStripeSubscription = createAsyncThunk(
  'basicInformation/getStripeSubscription',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getStripeSubscription(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateCitizenship = createAsyncThunk(
  'basicInformation/updateCitizenship',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myCitizenship = state.basicInformation.myCitizenship;
    try {
      if (myCitizenship) {
        const response = await basicInformationServices.updateCitizenship(payload);
        return response;
      } else {
        const response = await basicInformationServices.createCitizenship(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDemoGraphics = createAsyncThunk(
  'basicInformation/getDemoGraphics',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getDemoGraphics(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateDemoGraphics = createAsyncThunk(
  'basicInformation/updateDemoGraphics',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myDemographics = state.basicInformation.myDemographics;
    try {
      if (myDemographics) {
        const response = await basicInformationServices.updateDemographics(payload);
        return response;
      } else {
        const response = await basicInformationServices.createDemographics(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCurrentBasicDetail = createAsyncThunk(
  'basicInformation/getCurrentBasicDetail',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response: any = await basicInformationServices.getBasicDetail(studentId);
      if (response && response?.email) {
        const secret_key =
          process.env.REACT_APP_INTERCOM_SECRET_KEY || '_YFJ1ViqlxkO7JwPh1kGppKwORC4gpJD0ZzVjwte';

        const hash = crypto.createHmac('sha256', secret_key).update(response?.email).digest('hex');
        response.user_hash = hash;
      }

      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getContactDetail = createAsyncThunk(
  'basicInformation/getContactDetail',
  async (studentId: any, { rejectWithValue }) => {
    try {
      const response = await basicInformationServices.getContactDetail(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateContact = createAsyncThunk(
  'basicInformation/updateContact',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myContact = state.basicInformation.myContactDetail;
    try {
      if (myContact) {
        const response = await basicInformationServices.updateContact(payload);
        return response;
      } else {
        const response = await basicInformationServices.createContact(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const create = createAsyncThunk('basicInformation/create', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await basicInformationServices.create(payload);
    return response;
  }
  catch (err) {
    const error = err as AxiosError;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const update = createAsyncThunk('basicInformation/update', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await basicInformationServices.update(payload);
    return response;
  }
  catch (err) {
    const error = err as AxiosError;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getList = createAsyncThunk('basicInformation/getList', async (params: any, { rejectWithValue }) => {
  try {
    const response = await basicInformationServices.getList(params);
    return response;
  }
  catch (err) {
    const error = err as AxiosError;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getCBOList = createAsyncThunk('basicInformation/getgetCBOList', async (params: any, { rejectWithValue }) => {
  try {
    const response = await basicInformationServices.getCBOList();
    return response;
  }
  catch (err) {
    const error = err as AxiosError;
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('basicInformation/logout', async () => {
  //toast.success("You have been successfully logged out!");
  localStorage.removeItem('currentUserEmail');
  localStorage.removeItem('userRole');
  Cookies.remove(authenticateSettings.tokenName);
  Cookies.remove('userId');
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
  removeIntercomeCache();
  return true;
});
export const basicInformationSlice = createSlice({
  name: 'basicInformation',
  initialState,
  reducers: {
    setCheckModule: (state, action: any) => {
      let checkModule = sanitizeCheckedModule(action.payload);
      state.checkModule = checkModule;
    },
    setPartners: (state, action: any) => {
      state.partners = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(create.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(create.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.status = 'idle';
      })
      .addCase(update.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(update.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'idle';
        toast.success(action.payload.message);
      })
      .addCase(updateContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateContact.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.myContactDetail = action.payload;
        state.status = 'idle';
      })

      .addCase(getList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getList.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.listInformation = action.payload;
      })

      .addCase(getCurrentBasicDetail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurrentBasicDetail.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCurrentBasicDetail.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myBasicInformation = action.payload;
      })
      .addCase(getStripeSubscription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSubscription.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getStripeSubscription.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myStripeSubscription = action.payload;
      })
      .addCase(getStripeSubscriptionInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStripeSubscriptionInfo.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getStripeSubscriptionInfo.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.stripeSubscriptionInfo = action.payload;
      })
      .addCase(getContactDetail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContactDetail.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getContactDetail.fulfilled, (state, action: any) => {
        state.status = 'idle';
        state.myContactDetail = action.payload;
      })

      .addCase(getDemoGraphics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDemoGraphics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getDemoGraphics.fulfilled, (state, action: any) => {
        state.status = 'idle';
        if (action.payload) {
          state.myDemographics = action.payload;
        } else {
          state.myDemographics = null;
        }
      })

      .addCase(updateDemoGraphics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDemoGraphics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateDemoGraphics.fulfilled, (state, action: any) => {
        toast.success(action.payload.message);
        state.myDemographics = action.payload;
        state.status = 'idle';
      })
      //
      .addCase(getCitizenship.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCitizenship.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCitizenship.fulfilled, (state, action: any) => {
        state.status = 'idle';
        if (action.payload) {
          state.myCitizenship = action.payload;
        } else {
          state.myCitizenship = null;
        }
      })

      .addCase(updateCitizenship.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCitizenship.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateCitizenship.fulfilled, (state, action: any) => {
        toast.success(action.payload.message);
        state.myCitizenship = action.payload;
        state.status = 'idle';
      })

      .addCase(getCBOList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCBOList.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getCBOList.fulfilled, (state, action: any) => {
        state.CBOList = action.payload.cbo_list_array;
        state.status = 'idle';
      })
      .addCase(logout.fulfilled, (state: any) => {
        state.myBasicInformation = null;
      });
  },
});

export const { setCheckModule, setPartners } = basicInformationSlice.actions;
