/* eslint-disable react-hooks/exhaustive-deps */
import 'antd/dist/antd.css';
import 'antd.css';
import { Form as AntForm } from 'antd';
import React, { useState } from 'react';
import { Container, Form, Row, Button } from 'react-bootstrap';
import LoadingContent from 'components/LoadingContent';
import TopProcessBar from 'screen/selectCollege/TopProcessBar'
import SumarryTable from 'components/table/SumarryTable';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import gpaForecastServices from 'services/gpaforecast.services';
import gpaForecastDetailServices from 'services/gpaforecastDetail.services';
import EditableTable from 'components/table/antd-etable/antd-etable';
import _ from "lodash";
import FormCreateGpaForecastModal from './components/formCreateGpaForecastModal';
import moment from 'moment';

interface Props {}

const GpaForecaster = (props: Props) => {
  const [runCount, setRunCount] = React.useState(0);

  const [gpaForecast9, setGpaForecast9] = React.useState<any[]>([]);
  const [gpaForecast10, setGpaForecast10] = React.useState<any[]>([]);
  const [gpaForecast11, setGpaForecast11] = React.useState<any[]>([]);
  const [gpaForecast12, setGpaForecast12] = React.useState<any[]>([]);

  const [gpaForecast9Data, setGpaForecast9Data] = React.useState<any[]>([]);
  const [gpaForecast9Custom, setGpaForecast9Custom] = React.useState<any[]>([]);
  const [gpaForecast9Average, setGpaForecast9Average] = React.useState<any[]>([]);
  const [gpaForecast10Data, setGpaForecast10Data] = React.useState<any[]>([]);
  const [gpaForecast10Custom, setGpaForecast10Custom] = React.useState<any[]>([]);
  const [gpaForecast10Average, setGpaForecast10Average] = React.useState<any[]>([]);
  const [gpaForecast11Data, setGpaForecast11Data] = React.useState<any[]>([]);
  const [gpaForecast11Custom, setGpaForecast11Custom] = React.useState<any[]>([]);
  const [gpaForecast11Average, setGpaForecast11Average] = React.useState<any[]>([]);
  const [gpaForecast12Data, setGpaForecast12Data] = React.useState<any[]>([]); 
  const [gpaForecast12Custom, setGpaForecast12Custom] = React.useState<any[]>([]);
  const [gpaForecast12Average, setGpaForecast12Average] = React.useState<any[]>([]);

  const [currentPage9Data,setCurrentPage9Data] = useState(1);
  const [currentRow9Data,setCurrentRow9Data] = useState<any>(null);
  const [changedData9Data,setChangedData9Data] = useState<any[]>([]);
  const [form9] = AntForm.useForm();

  const [currentPage10Data,setCurrentPage10Data] = useState(1);
  const [currentRow10Data,setCurrentRow10Data] = useState<any>(null);
  const [changedData10Data,setChangedData10Data] = useState<any[]>([]);
  const [form10] = AntForm.useForm();

  const [currentPage11Data,setCurrentPage11Data] = useState(1);
  const [currentRow11Data,setCurrentRow11Data] = useState<any>(null);
  const [changedData11Data,setChangedData11Data] = useState<any[]>([]);
  const [form11] = AntForm.useForm();

  const [currentPage12Data,setCurrentPage12Data] = useState(1); 
  const [currentRow12Data,setCurrentRow12Data] = useState<any>(null);
  const [changedData12Data,setChangedData12Data] = useState<any[]>([]);
  const [form12] = AntForm.useForm();

  const [currentPage9Custom,setCurrentPage9Custom] = useState(1);
  const [currentRow9Custom,setCurrentRow9Custom] = useState<any>(null);
  const [changedData9Custom,setChangedData9Custom] = useState<any[]>([]);
  const [form9Custom] = AntForm.useForm();
  const [schoolOptions9, setSchoolOptions9] = useState<any[]>([]);
  const [openAddDialog9, setOpenAddDialog9] = useState<boolean>(false);

  const [currentPage10Custom,setCurrentPage10Custom] = useState(1);
  const [currentRow10Custom,setCurrentRow10Custom] = useState<any>(null);
  const [changedData10Custom,setChangedData10Custom] = useState<any[]>([]);
  const [form10Custom] = AntForm.useForm();
  const [schoolOptions10, setSchoolOptions10] = useState<any[]>([]); 
  const [openAddDialog10, setOpenAddDialog10] = useState<boolean>(false);

  const [currentPage11Custom,setCurrentPage11Custom] = useState(1);
  const [currentRow11Custom,setCurrentRow11Custom] = useState<any>(null);
  const [changedData11Custom,setChangedData11Custom] = useState<any[]>([]);
  const [form11Custom] = AntForm.useForm();
  const [schoolOptions11, setSchoolOptions11] = useState<any[]>([]); 
  const [openAddDialog11, setOpenAddDialog11] = useState<boolean>(false);

  const [currentPage12Custom,setCurrentPage12Custom] = useState(1); 
  const [currentRow12Custom,setCurrentRow12Custom] = useState<any>(null);
  const [changedData12Custom,setChangedData12Custom] = useState<any[]>([]);
  const [form12Custom] = AntForm.useForm();
  const [schoolOptions12, setSchoolOptions12] = useState<any[]>([]); 
  const [openAddDialog12, setOpenAddDialog12] = useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);
  const [loading9, setLoading9] = React.useState(false);
  const [loading10, setLoading10] = React.useState(false);
  const [loading11, setLoading11] = React.useState(false);
  const [loading12, setLoading12] = React.useState(false);

  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };


  const handleContinue = () => {
    history.push(routers.personalSum.path.replace(':id', studentId));
  };
  

  const getGpaForeCastByGradeNo  = async(studentId: string, gradeNo: number) => {
    const ret:any[] = await gpaForecastServices.getGpaForeCastByGradeNo(studentId, gradeNo);
    return ret;
  };

  const getGpaDetails = async(ret:any[]) => {
    let arr:any[] = [];
    if (ret && ret.length>0) {
      for (let i=0; i<ret.length; i++) {
          let item = ret[i];
          let ret2:any[] = await gpaForecastDetailServices.getGpaForeCastDetails(item.gpa_forecast_id);
          if (ret2 && ret2.length>0) {
            for(let j=0; j<ret2.length; j++) {
              arr.push(ret2[j]);
            }
          }
      }        
    }
    return arr;
  }

  const formatDecial =(num:number) =>{
    return (Math.round(num * 100) / 100).toFixed(2);
  } 
  
  const calcAverageGpa = (data:any[]) => {
    if (data && data.length>0) {
      let sum1 = 0;
      let sum2 = 0;
      let sumCredit = 0;
      for(let i=0; i< data.length; i++) {
        sum1 += data[i].unweighted_point * data[i].credit;
        sum2 += data[i].weighted_point * data[i].credit;
        sumCredit += data[i].credit;
      }
      return { 
        unweighted_point: sum1 !== 0 ? sum1/sumCredit : 0, 
        weighted_point:   sum2 !== 0 ? sum2/sumCredit : 0, 
      }
    }
    return { unweighted_point: 0, weighted_point: 0 }
  }

  const CalcAverageGpaPoint = (data:any[], gradeNo: number) => {
    let gradeth = `${gradeNo}th Grade`;
    if (data && data.length>0) {
      let sum1 = 0;
      let sum2 = 0;
      let sumCredit = 0;
      for(let i=0; i< data.length; i++) {
        sum1 += data[i].unweighted_point * data[i].credit;
        sum2 += data[i].weighted_point * data[i].credit;
        sumCredit += data[i].credit;
      }
      return [{ 
        grade: gradeth, 
        unweighted_point: sum1 !== 0 ? formatDecial(sum1/sumCredit) : 0, 
        weighted_point:   sum2 !== 0 ? formatDecial(sum2/sumCredit) : 0,
      }]
    }
    return [{ grade: gradeth, unweighted_point: 0, weighted_point: 0}]
  }
  const CalcAverageGpa_10Grade = (data9:any[], data10:any[]) => {
    let gradeth = '9th-10th Grade';
    let arr:any[] = [];
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let unweighted_point = (avg9.unweighted_point + avg10.unweighted_point) /2;
    let weighted_point= (avg9.weighted_point + avg10.weighted_point) /2;
    arr.push(CalcAverageGpaPoint(data10, 10)[0]);
    arr.push({grade: gradeth, unweighted_point: formatDecial(unweighted_point), weighted_point: formatDecial(weighted_point)});
    return arr;
  }

  const CalcAverageGpa_11Grade = (data9:any[], data10:any[], data11:any[]) => {
    let grade_9_11th = '9th-11th Grade';
    let grade_10_11th = '10th-11th Grade';  
    let arr:any[] = []; 
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let avg11:any = calcAverageGpa(data11);
    let unweighted_point_9_11 = (avg9.unweighted_point + avg10.unweighted_point + avg11.unweighted_point) /3;
    let weighted_point_9_11= (avg9.weighted_point + avg10.weighted_point + avg11.weighted_point) /3;
    arr.push(CalcAverageGpaPoint(data11, 11)[0]);
    arr.push({grade: grade_9_11th, unweighted_point: formatDecial(unweighted_point_9_11), weighted_point: formatDecial(weighted_point_9_11)});
    let unweighted_point_10_11 = (avg10.unweighted_point + avg11.unweighted_point) /2;
    let weighted_point_10_11= (avg10.weighted_point + avg11.weighted_point) /2;
    arr.push({grade: grade_10_11th, unweighted_point: formatDecial(unweighted_point_10_11), weighted_point: formatDecial(weighted_point_10_11)});   
    return arr;
  }

  const CalcAverageGpa_12Grade = (data9:any[], data10:any[], data11:any[], data12:any[]) => {
    let grade_9_12th = '9th-12th Grade';
    let grade_10_12th = '10th-12th Grade';  
    let arr:any[] = []; 
    let avg9:any = calcAverageGpa(data9);
    let avg10:any = calcAverageGpa(data10);
    let avg11:any = calcAverageGpa(data11);
    let avg12:any = calcAverageGpa(data12);
    let unweighted_point_9_12 = (avg9.unweighted_point + avg10.unweighted_point + avg11.unweighted_point + avg12.unweighted_point) /4;
    let weighted_point_9_12= (avg9.weighted_point + avg10.weighted_point + avg11.weighted_point + avg12.weighted_point) /4;
    arr.push(CalcAverageGpaPoint(data12, 12)[0]);
    arr.push({grade: grade_9_12th, unweighted_point: formatDecial(unweighted_point_9_12), weighted_point: formatDecial(weighted_point_9_12)});
    let unweighted_point_10_12 = (avg10.unweighted_point + avg11.unweighted_point + avg12.unweighted_point) /3;
    let weighted_point_10_12= (avg10.weighted_point + avg11.weighted_point + avg12.weighted_point) /3;
    arr.push({grade: grade_10_12th, unweighted_point: formatDecial(unweighted_point_10_12), weighted_point: formatDecial(weighted_point_10_12)});   
    return arr;
  }

  const mergeArrayGpa = (arrOrigin:any[], arrCustom:any[]) => {
    let arr:any[] = [];
    if (arrOrigin && arrOrigin.length>0) {
      for(let i=0; i< arrOrigin.length; i++) {
        arr.push(arrOrigin[i]);
      }
    }
    if (arrCustom && arrCustom.length>0) {
      for(let j=0; j< arrCustom.length; j++) {
        arr.push(arrCustom[j]);
      }
    }
    return arr;
  };

  const updateCalcAllData = () => {
    let arr9: any [] = mergeArrayGpa(gpaForecast9Data, gpaForecast9Custom);
    let arr10:any [] = mergeArrayGpa(gpaForecast10Data, gpaForecast10Custom);
    let arr11:any [] = mergeArrayGpa(gpaForecast11Data, gpaForecast11Custom);
    let arr12:any [] = mergeArrayGpa(gpaForecast12Data, gpaForecast12Custom);
    
    setGpaForecast9Average(CalcAverageGpaPoint(arr9, 9));
    setGpaForecast10Average(CalcAverageGpa_10Grade(arr9, arr10));
    setGpaForecast11Average(CalcAverageGpa_11Grade(arr9, arr10, arr11));
    setGpaForecast12Average(CalcAverageGpa_12Grade(arr9, arr10, arr11, arr12));
  }

  function compareDate(a:any, b:any) {
    return moment(a.updated_at).unix() - moment(b.updated_at).unix();
  }
  function compareSeqNo( a:any, b:any ) {
    if ( a.seq_no < b.seq_no ){
      return -1;
    }
    if ( a.seq_no > b.seq_no ){
      return 1;
    }
    return 0;
  }

  const sortGpaDetails = (arr: any[]) =>{   
    arr.sort(compareDate);
  }

  const refreshGpa = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading12(true);
      try {
        let arr9 = await getGpaForeCastByGradeNo(studentId, 9);
        setGpaForecast9(arr9);

        let arr9_details = await getGpaDetails(arr9);     
        let arr9_origin:any[] = arr9_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast9Data(arr9_origin);

        let arr9_custom:any[] = arr9_details.filter( (item:any) => (item.is_custom === true));
        setGpaForecast9Custom(arr9_custom);
        setGpaForecast9Average(CalcAverageGpaPoint(arr9_details, 9));

        let arr10 = await getGpaForeCastByGradeNo(studentId, 10);
        setGpaForecast10(arr10);

        let arr10_details = await getGpaDetails(arr10);
        let arr10_origin:any[] = arr10_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast10Data(arr10_origin);

        let arr10_custom:any[] = arr10_details.filter( (item:any) => (item.is_custom === true));
        setGpaForecast10Custom(arr10_custom);
        setGpaForecast10Average(CalcAverageGpa_10Grade(arr9_details, arr10_details));

        let arr11 = await getGpaForeCastByGradeNo(studentId, 11);
        setGpaForecast11(arr11);

        let arr11_details = await getGpaDetails(arr11);
        let arr11_origin:any[] = arr11_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast11Data(arr11_origin);

        let arr11_custom:any[] = arr11_details.filter( (item:any) => (item.is_custom === true));
        setGpaForecast11Custom(arr11_custom);
        setGpaForecast11Average(CalcAverageGpa_11Grade(arr9_details, arr10_details, arr11_details));

        let arr12 = await getGpaForeCastByGradeNo(studentId, 12);
        setGpaForecast12(arr12);

        let arr12_details = await getGpaDetails(arr12);
        let arr12_origin:any[] = arr12_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast12Data(arr12_origin);
        
        let arr12_custom:any[] = arr12_details.filter( (item:any) => (item.is_custom === true));
        setGpaForecast12Custom(arr12_custom);
        setGpaForecast12Average(CalcAverageGpa_12Grade(arr9_details, arr10_details, arr11_details, arr12_details))

        //#region create school options
        let list9 = arr9.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions9(list9);

        let list10 = arr10.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions10(list10);
       
        let list11 = arr11.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions11(list11);

        let list12 = arr12.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions12(list12);
        //#endregion
      }
      catch(ex){
        console.log('Error:', ex);
      }
      setLoading12(false);
    }  
  }

  const genSeqNoDetails = (data:any[]) => {
      let max = 0;
      try {
        if (data && data.length>0)
          for(let i=0; i<data.length; i++) {
            let obj:any = data[i];
            if (max < obj.seq_no) {
              max = obj.seq_no;
            }
          }
      }
      catch(ex){
        //skip
      }
      return max+1;
  }
  const getData = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      try { 
        let arr9 = await getGpaForeCastByGradeNo(studentId, 9);
        setGpaForecast9(arr9);
        let arr9_details = await getGpaDetails(arr9);
        let arr9_origin:any[] = arr9_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast9Data(arr9_origin);
        //console.log('arr9_origin: ', arr9_origin);

        let arr9_custom:any[] = arr9_details.filter( (item:any) => (item.is_custom === true));
        //sortGpaDetails(arr9_custom);
        setGpaForecast9Custom(arr9_custom);
        setGpaForecast9Average(CalcAverageGpaPoint(arr9_details, 9));
        //console.log('arr9_details: ', arr9_details);
  
        let arr10 = await getGpaForeCastByGradeNo(studentId, 10);
        setGpaForecast10(arr10);
        let arr10_details = await getGpaDetails(arr10);
        let arr10_origin:any[] = arr10_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast10Data(arr10_origin);
        //console.log('arr10_origin: ', arr10_origin);
  
        let arr10_custom:any[] = arr10_details.filter( (item:any) => (item.is_custom === true));
        //sortGpaDetails(arr10_custom);
        setGpaForecast10Custom(arr10_custom);
        setGpaForecast10Average(CalcAverageGpa_10Grade(arr9_details, arr10_details));
        //console.log('arr10_custom: ', arr10_custom);

        let arr11 = await getGpaForeCastByGradeNo(studentId, 11);
        setGpaForecast11(arr11);
        let arr11_details = await getGpaDetails(arr11);
        let arr11_origin:any[] = arr11_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast11Data(arr11_origin);
        //console.log('arr11_origin: ', arr11_origin);

        let arr11_custom:any[] = arr11_details.filter( (item:any) => (item.is_custom === true));
        //sortGpaDetails(arr11_custom);
        setGpaForecast11Custom(arr11_custom);
        setGpaForecast11Average(CalcAverageGpa_11Grade(arr9_details, arr10_details, arr11_details));
        //console.log('arr11_custom: ', arr11_custom);

        let arr12 = await getGpaForeCastByGradeNo(studentId, 12);
        setGpaForecast12(arr12);
        let arr12_details = await getGpaDetails(arr12);
        let arr12_origin:any[] = arr12_details.filter( (item:any) => (item.is_custom === false));
        setGpaForecast12Data(arr12_origin);
        //console.log('arr12_details: ', arr12_details);
        //console.log('arr12_origin: ', arr12_origin);
        
        let arr12_custom:any[] = arr12_details.filter( (item:any) => (item.is_custom === true));
        //sortGpaDetails(arr12_custom);
        setGpaForecast12Custom(arr12_custom);
        setGpaForecast12Average(CalcAverageGpa_12Grade(arr9_details, arr10_details, arr11_details, arr12_details))
        //console.log('arr12_custom: ', arr12_custom);

        //#region Create School Options
        let list9 = arr9.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions9(list9);

        let list10 = arr10.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions10(list10);
       
        let list11 = arr11.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions11(list11);

        let list12 = arr12.map((value:any) => ({ 
          name: value.school_name, 
          value: value.gpa_forecast_id,
          gpa_forecast_id: value.gpa_forecast_id,
          school_name: value.school_name,
          schedule: value.schedule,
          grading_scale: value.grading_scale,
        }));
        setSchoolOptions12(list12);
        //#endregion

        if (arr9.length === 0 && arr10.length === 0 && arr11.length===0 && arr12.length ===0) {
          if (runCount===0) {
            setRunCount(runCount+1);
            await runResync();
          }
        }
      }
      catch(ex) {
        console.log('Error: ', ex);
      }    
      setLoading(false);
    } else {
      handleLogout();
    }
  };

  const runResync = async() => {
    setLoading9(true);
    setLoading10(true);
    setLoading11(true);
    setLoading12(true);
    const ret:any = await gpaForecastServices.syncGpaForecast(studentId);
    setLoading9(false);
    setLoading10(false);
    setLoading11(false);
    setLoading12(false);
    if (ret && ret.statusCode === 200) {
      await getData();
    }
  }

  React.useEffect(() => {
    const syncData = async () => {
      if (!loading) {
          await getData();
      }
    };
    syncData();
  }, []);

  const handleRefresh = async () => {
    await runResync();
  };


  const grade9_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: false,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: false,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: false,
    },
  ];
  const grade9_custom_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: true,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: true,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: true,
    },
  ];

  const grade10_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: false,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: false,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: false,
    },
  ];
  const grade10_custom_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: true,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: true,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: true,
    },
  ];


  const grade11_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: false,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: false,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: false,
    },
  ];
  const grade11_custom_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: true,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: true,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: true,
    },
  ];

  const grade12_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: false,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: false,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: false,
    },
  ];
  const grade12_custom_columns = [
    {
      title: 'School Name',
      dataIndex: 'school_name',
      width: '20%',
      editable: false,
    },
    {
      title: 'Course Name',
      dataIndex: 'course',
      width: '20%',
      editable: true,
    },
    {
      title: 'Term',
      dataIndex: 'term',
      editable: false,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '10%',
      editable: true,
    },
    {
      title: 'Unweighted Point',
      dataIndex: 'unweighted_point',
      width: 90,
      editable: true,
      editor: {
        required: true,
        validator: (rule:any,value:any,callback:any,record:any) => {
          if (value==='' || isNaN(Number(value))) {
            callback('You must input numbers!');
          }
          else {
            callback();
          }
        },
      },
    },
    {
      title: 'Weighted Point',
      dataIndex: 'weighted_point',
      width: 85,
      editable: true,
    },
    {
      title: 'Credits',
      dataIndex: 'credit',
      width: 80,
      editable: true,
    },
  ];

  const handleFormChange9Data = (values:any)=>{
    console.log('run handleFormChange9Data')
    if(currentRow9Data){
      const isExistRow = changedData9Data.find(c => c.id === currentRow9Data.id);

      if(!isExistRow){
        const updateData:any[] = [...changedData9Data, { id:currentRow9Data.id, ...values, isUpdate: true}];
        setChangedData9Data(updateData);
      }else {
        const updateData:any[] = changedData9Data.map((c:any) => {
          if (c.id === currentRow9Data.id) {
            return _.merge({}, c, values);
          } else {
            return c;
          }
        });
        setChangedData9Data(updateData);
      }
    }
  };
  
  const handleFormChange9Custom = (values:any)=>{
    if(currentRow9Custom){
      const isExistRow = changedData9Custom.find(c => c.id === currentRow9Custom.id);

      if(!isExistRow){
        const updateData:any[] = [...changedData9Custom, { id:currentRow9Custom.id, ...values, isUpdate: true}];
        setChangedData9Custom(updateData);
      }else {
        const updateData:any[] = changedData9Custom.map((c:any) => {
          if (c.id === currentRow9Custom.id) {
            return _.merge({}, c, values);
          } else {
            return c;
          }
        });
        setChangedData9Custom(updateData);
      }
    }
  };

  const onChangedDataUpdate9Data = async (dataArr:any) => {
    let updatedDataSource:any[] = [...gpaForecast9Data];
    setLoading9(true);
    try {
      for(let i=0; i< dataArr.length; i++) {
        let data = dataArr[i];
        let row = updatedDataSource.find(c => c.id === data.id);
        if (row) {
          if (data.isUpdate) {
            if (data.hasOwnProperty('unweighted_point')) {
              let unweighted_point = Number(data.unweighted_point);
              row.is_weight_point = false;
              // if (row.unweighted_point!== unweighted_point) {
              //   row.is_override = true;
              // }
              row.unweighted_point = unweighted_point;
              // if (row.weighted_point !== 0) {
              //   row.weighted_point = 0;
              //   row.is_override = true;
              // }

              await gpaForecastDetailServices.updateGpaForeCastDetail({
                id: row.id,
                gpa_forecast_id: row.gpa_forecast_id,
                is_custom: row.is_custom,
                is_override: row.is_override,
                seq_no: row.seq_no,
                school_name: row.school_name,
                summer_course: row.summer_course,
                course: row.course,
                term: row.term,
                grade: row.grade,
                is_weight_point: row.is_weight_point,
                unweighted_point: row.unweighted_point,
                weighted_point: row.weighted_point,
              });
              setGpaForecast9Data(updatedDataSource);
            }
            if (data.hasOwnProperty('weighted_point')) {
              let weighted_point = Number(data.weighted_point);
              row.is_weight_point = true;
              // if (row.weighted_point!== weighted_point) {
              //   row.is_override = true;
              // }
              row.weighted_point = weighted_point;
              // if (row.unweighted_point !== 0) {
              //   row.unweighted_point = 0;
              //   row.is_override = true;
              // }
              
              await gpaForecastDetailServices.updateGpaForeCastDetail({
                id: row.id,
                gpa_forecast_id: row.gpa_forecast_id,
                is_custom: row.is_custom,
                is_override: row.is_override,
                seq_no: row.seq_no,
                school_name: row.school_name,
                summer_course: row.summer_course,
                course: row.course,
                term: row.term,
                grade: row.grade,
                is_weight_point: row.is_weight_point,
                unweighted_point: row.unweighted_point,
                weighted_point: row.weighted_point,
              });
              setGpaForecast9Data(updatedDataSource);
            }
            await refreshGpa();
          }
          else {
            if (data.isDelete) {
              updatedDataSource= gpaForecast9Data.filter((o:any) => o.id !== data.id);
              let ret = await gpaForecastDetailServices.deleteGpaForeCastDetail(data.id);
              if (ret) {
                setGpaForecast9Data(updatedDataSource);
                await refreshGpa();
              }
            }
          }   
        }
      }   
    }
    catch(ex){
      console.log('Error:', ex);    
    }
    setLoading9(false);
  }

  const onChangedDataUpdate9Custom = async (dataArr:any) => {
    let updatedDataSource:any[] = [...gpaForecast9Custom];
    setLoading(true);
    for(let i=0; i< dataArr.length; i++) {
      let data = dataArr[i];
      let row = updatedDataSource.find(c => c.id === data.id);
      if (row) {
        if (data.isUpdate) {
          row.is_custom = true;
          if (data.hasOwnProperty('course')) {
            row.course = data.course;
          }
          if (data.hasOwnProperty('grade')) {
            row.grade = data.grade;
          }
          if (data.hasOwnProperty('unweighted_point')) {
            let unweighted_point = Number(data.unweighted_point);
            row.is_weight_point = false;
            row.unweighted_point = unweighted_point;
            // if (row.weighted_point !== 0) {
            //   row.weighted_point = 0;
            // }

            await gpaForecastDetailServices.updateGpaForeCastDetail({
              id: row.id,
              gpa_forecast_id: row.gpa_forecast_id,
              is_custom: row.is_custom,
              is_override: row.is_override,
              seq_no: row.seq_no,
              school_name: row.school_name,
              summer_course: row.summer_course,
              course: row.course,
              term: row.term,
              grade: row.grade,
              is_weight_point: row.is_weight_point,
              unweighted_point: row.unweighted_point,
              weighted_point: row.weighted_point,
            });
            setGpaForecast9Custom(updatedDataSource);
          }
          if (data.hasOwnProperty('weighted_point')) {
            let weighted_point = Number(data.weighted_point);
            row.is_weight_point = true;
            row.weighted_point = weighted_point;
            // if (row.unweighted_point !== 0) {
            //   row.unweighted_point = 0;
            // }
            
            await gpaForecastDetailServices.updateGpaForeCastDetail({
              id: row.id,
              gpa_forecast_id: row.gpa_forecast_id,
              is_custom: row.is_custom,
              is_override: row.is_override,
              seq_no: row.seq_no,
              school_name: row.school_name,
              summer_course: row.summer_course,
              course: row.course,
              term: row.term,
              grade: row.grade,
              is_weight_point: row.is_weight_point,
              unweighted_point: row.unweighted_point,
              weighted_point: row.weighted_point,
            });
            setGpaForecast9Custom(updatedDataSource);
          }
          await refreshGpa();
        }
        else {
          if (data.isDelete) {
            updatedDataSource= gpaForecast9Custom.filter((o:any) => o.id !== data.id);
            let ret = await gpaForecastDetailServices.deleteGpaForeCastDetail(data.id);
            if (ret) {
              setGpaForecast9Custom(updatedDataSource);
              await refreshGpa();
            }
          }
        }   
      }
    }
    setLoading(false);
  }

  const onChangedDataUpdateData = async (dataArr:any, gradeNo: number) => {  
    const setUpdateGpaForecastData = (ds:any[], gradeNo:number) =>{
      switch(gradeNo) {
        case 9:
          setGpaForecast9Data(ds);
          break;
        case 10:
          setGpaForecast10Data(ds);
          break;
        case 11:
          setGpaForecast11Data(ds);
          break;
        case 12:
          setGpaForecast12Data(ds);
          break;          
      }
    }

    let gpaForecastData:any[] = [];
    let updatedDataSource:any[] = [];
    switch(gradeNo) {
      case 9:
        gpaForecastData = gpaForecast9Data;
        updatedDataSource = [...gpaForecast9Data];
        break;
      case 10:
        gpaForecastData = gpaForecast10Data;
        updatedDataSource = [...gpaForecast10Data];
        break;
      case 11:
        gpaForecastData = gpaForecast11Data;
        updatedDataSource = [...gpaForecast11Data];
        break;
      case 12:
        gpaForecastData = gpaForecast12Data;
        updatedDataSource = [...gpaForecast12Data];
        break;
    }
    setLoadingNo(gradeNo, true);
    try {
      for(let i=0; i< dataArr.length; i++) {
        let data = dataArr[i];
        let row = updatedDataSource.find(c => c.id === data.id);
        if (row) {
          if (data.isUpdate) {
            if (data.hasOwnProperty('unweighted_point')) {
              let unweighted_point = Number(data.unweighted_point);
              row.is_weight_point = false;
              // if (row.unweighted_point!== unweighted_point) {
              //   row.is_override = true;
              // }
              row.unweighted_point = unweighted_point;
              // if (row.weighted_point !== 0) {
              //   row.weighted_point = 0;
              //   row.is_override = true;
              // }
            }
            if (data.hasOwnProperty('weighted_point')) {
              let weighted_point = Number(data.weighted_point);
              row.is_weight_point = true;
              // if (row.weighted_point!== weighted_point) {
              //   row.is_override = true;
              // }
              row.weighted_point = weighted_point;
              // if (row.unweighted_point !== 0) {
              //   row.unweighted_point = 0;
              //   row.is_override = true;
              // }
            
            }

            await gpaForecastDetailServices.updateGpaForeCastDetail({
              id: row.id,
              gpa_forecast_id: row.gpa_forecast_id,
              is_custom: row.is_custom,
              is_override: row.is_override,
              seq_no: row.seq_no,
              school_name: row.school_name,
              summer_course: row.summer_course,
              course: row.course,
              term: row.term,
              grade: row.grade,
              is_weight_point: row.is_weight_point,
              unweighted_point: row.unweighted_point,
              weighted_point: row.weighted_point,
            });
            setUpdateGpaForecastData(updatedDataSource, gradeNo);
            await refreshGpa();
          }
          else {
            if (data.isDelete) {
              updatedDataSource= gpaForecastData.filter((o:any) => o.id !== data.id);
              let ret = await gpaForecastDetailServices.deleteGpaForeCastDetail(data.id);
              if (ret) {
                setUpdateGpaForecastData(updatedDataSource, gradeNo);
                await refreshGpa();
              }
            }
          }   
        }
      }
    }
    catch(ex){
      console.log('Error:', ex);
    }
    setLoadingNo(gradeNo, false);
  }
  
  const setLoadingNo = (gradeNo: number, value:boolean) => {
    switch(gradeNo) {
      case 9:
        setLoading9(value);
        break;
      case 10:
        setLoading10(value);
        break;
      case 11:
        setLoading11(value);
        break;
      case 12:
        setLoading12(value);
        break;
    }    
  }

  const onChangedDataUpdateCustom = async (dataArr:any, gradeNo: number) => {
    const setUpdateGpaForecastCustom = (ds:any[], gradeNo:number) =>{
      switch(gradeNo) {
        case 9:
          setGpaForecast9Custom(ds);
          break;
        case 10:
          setGpaForecast10Custom(ds);
          break;
        case 11:
          setGpaForecast11Custom(ds);
          break;
        case 12:
          setGpaForecast12Custom(ds);
          break;          
      }
    };

    let gpaForecastCustom:any[] = [];
    let updatedDataSource:any[] = [];
    switch(gradeNo) {
      case 9:
        gpaForecastCustom = gpaForecast9Custom;
        updatedDataSource = [...gpaForecast9Custom];
        break;
      case 10:
        gpaForecastCustom = gpaForecast10Custom;
        updatedDataSource = [...gpaForecast10Custom];
        break;
      case 11:
        gpaForecastCustom = gpaForecast11Custom;
        updatedDataSource = [...gpaForecast11Custom];
        break;
      case 12:
        gpaForecastCustom = gpaForecast12Custom;
        updatedDataSource = [...gpaForecast12Custom];
        break;
    }
    setLoadingNo(gradeNo, true);
    try {
      for(let i=0; i< dataArr.length; i++) {
        let data = dataArr[i];
        let row = updatedDataSource.find(c => c.id === data.id);
        if (row) {
          if (data.isUpdate) {
            row.is_custom = true;
            if (data.hasOwnProperty('course')) {
              row.course = data.course;
            }
            if (data.hasOwnProperty('grade')) {
              row.grade = data.grade;
            }
            if (data.hasOwnProperty('unweighted_point')) {
              let unweighted_point = Number(data.unweighted_point);
              row.is_weight_point = false;
              row.unweighted_point = unweighted_point;
              // if (row.weighted_point !== 0) {
              //   row.weighted_point = 0;
              // }
            }
            if (data.hasOwnProperty('weighted_point')) {
              let weighted_point = Number(data.weighted_point);
              row.is_weight_point = true;
              row.weighted_point = weighted_point;
              // if (row.unweighted_point !== 0) {
              //   row.unweighted_point = 0;
              // }
            }

            let res = await gpaForecastDetailServices.updateGpaForeCastDetail({
              id: row.id,
              gpa_forecast_id: row.gpa_forecast_id,
              is_custom: row.is_custom,
              is_override: row.is_override,
              seq_no: row.seq_no,
              school_name: row.school_name,
              summer_course: row.summer_course,
              course: row.course,
              term: row.term,
              grade: row.grade,
              is_weight_point: row.is_weight_point,
              unweighted_point: row.unweighted_point,
              weighted_point: row.weighted_point,
            });
            setUpdateGpaForecastCustom(updatedDataSource, gradeNo);
            await refreshGpa();
          }
          else {
            if (data.isDelete) {
              updatedDataSource = updatedDataSource.filter((o:any) => o.id !== data.id);

              let ret = await gpaForecastDetailServices.deleteGpaForeCastDetail(data.id);
              if (ret) {
                setUpdateGpaForecastCustom(updatedDataSource, gradeNo);
                updateCalcAllData();
                await refreshGpa();
              }

            }
          }   
        }
      }   
    }
    catch(ex){
      console.log('Error:', ex);
    }
    setLoadingNo(gradeNo, false);
  }

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="GPA Forecaster"
        process={1}
        icon={<EventNoteOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} >
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    GPA Forecaster
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <div>

              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">GPA Forecaster</div>
                <div className="d-flex text-field-mid fs-5 mb-3 py-4">Non-GPA Courses are not included in the table below.</div>
              </Row>
              <div className="row">
                  <div className="col-md-12">
                      <h2>Grade 9th</h2>
                  </div>
                  <div className="col-md-12">
                    <AntForm form={form9} onValuesChange={handleFormChange9Data} >
                      <EditableTable
                        loading={loading9}
                        cols={grade9_columns}
                        data={gpaForecast9Data}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        currentPage={currentPage9Data}
                        onChangedDataUpdate={async(d:any)=>{
                            setChangedData9Data(d);
                            await onChangedDataUpdate9Data(d);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow9Data(rows[0])
                        }}
                      />
                    </AntForm>
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 9th - Custom Data</h3>
                    <AntForm form={form9Custom} >
                      <EditableTable
                        loading={loading9}
                        cols={grade9_custom_columns}
                        data={gpaForecast9Custom}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        showDeleteButton={true}
                        currentPage={currentPage9Custom}
                        onChangedDataUpdate={async(d:any)=>{
                            setChangedData9Custom(d);
                            await onChangedDataUpdateCustom(d, 9);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow9Custom(rows[0])
                        }}
                        buttons={(
                          <>
                            <Button size="sm" onClick={(e:any)=>{
                              setOpenAddDialog9(true);
                            }}>Add Row</Button>
                          </> 
                        )}  
                      />
                    </AntForm>
                    <FormCreateGpaForecastModal
                      options={schoolOptions9}
                      open={openAddDialog9}
                      handleSave={async(values:any) => {
                        setOpenAddDialog9(false);
                        setLoading9(true);
                        try {
                          let seq_no = genSeqNoDetails(gpaForecast9Custom);
                          await gpaForecastDetailServices.createGpaForeCastDetail({
                            gpa_forecast_id: Number(values.gpa_forecast_id),
                            student_id: studentId,
                            is_custom: true,
                            is_override: false,
                            seq_no: seq_no,
                            school_name: values.school_name,
                            summer_course: false,
                            course: values.course,
                            term: values.term,
                            grade: values.grade,
                            credit: values.credit,
                            is_weight_point: values.is_weight_point,
                            unweighted_point: Number(values.unweighted_point ?? 0),
                            weighted_point: Number(values.weighted_point ?? 0),
                          });
                          updateCalcAllData();
                          await refreshGpa();
                        }
                        catch(ex){
                          console.log('Error: ', ex);
                        }
                        setLoading9(false);
                      }}
                      onClose={() => {
                        setOpenAddDialog9(false);
                      }}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast9Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr/>

                  <div className="col-md-12 py-4">
                    <h2>Grade 10th</h2>
                  </div>
                  <div className="col-md-12">
                    <AntForm form={form10}>
                      <EditableTable
                        loading={loading10}
                        cols={grade10_columns}
                        data={gpaForecast10Data}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        currentPage={currentPage10Data}
                        onChangedDataUpdate={async(d:any)=>{
                            setChangedData10Data(d);
                            await onChangedDataUpdateData(d, 10);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow10Data(rows[0])
                        }}
                      />
                    </AntForm>
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 10th - Custom Data</h3>
                    <AntForm form={form10Custom} >
                      <EditableTable
                        loading={loading10}
                        cols={grade10_custom_columns}
                        data={gpaForecast10Custom}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        showDeleteButton={true}
                        currentPage={currentPage10Custom}
                        onChangedDataUpdate={async(d:any)=>{
                            setChangedData10Custom(d);
                            await onChangedDataUpdateCustom(d, 10);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow10Custom(rows[0])
                        }}
                        buttons={(
                          <>
                            <Button size="sm" onClick={(e:any)=>{
                              setOpenAddDialog10(true);
                            }}>Add Row</Button>
                          </> 
                        )}  
                      />
                    </AntForm>
                    <FormCreateGpaForecastModal
                      options={schoolOptions10}
                      open={openAddDialog10}
                      handleSave={async(values:any) => {
                        setOpenAddDialog10(false);
                        setLoading10(true);
                        try {
                          let seq_no = genSeqNoDetails(gpaForecast10Custom);
                          await gpaForecastDetailServices.createGpaForeCastDetail({
                            gpa_forecast_id: Number(values.gpa_forecast_id),
                            student_id: studentId,
                            is_custom: true,
                            is_override: false,
                            seq_no: seq_no,
                            school_name: values.school_name,
                            summer_course: false,
                            course: values.course,
                            term: values.term,
                            grade: values.grade,
                            credit: values.credit,
                            is_weight_point: values.is_weight_point,
                            unweighted_point: Number(values.unweighted_point ?? 0),
                            weighted_point: Number(values.weighted_point ?? 0),
                          });
                          updateCalcAllData();
                          await refreshGpa();
                        }
                        catch(ex) {
                          console.log('Error: ', ex);
                        }
                        setLoading10(false);
                      }}
                      onClose={() => {
                        setOpenAddDialog10(false);
                      }}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast10Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr/>

                  <div className="col-md-12 py-4">
                    <h2>Grade 11th</h2>
                  </div>
                  <div className="col-md-12">
                    <AntForm form={form11} >
                      <EditableTable
                        loading={loading11}
                        cols={grade11_columns}
                        data={gpaForecast11Data}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        currentPage={currentPage11Data}
                        onChangedDataUpdate={async (d:any)=>{
                            setChangedData11Data(d);
                            await onChangedDataUpdateData(d, 11);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow11Data(rows[0])
                        }}
                      />
                    </AntForm>
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 11th - Custom Data</h3>
                    <AntForm form={form11Custom} >
                      <EditableTable
                        loading={loading11}
                        cols={grade11_custom_columns}
                        data={gpaForecast11Custom}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        showDeleteButton={true}
                        currentPage={currentPage11Custom}
                        onChangedDataUpdate={async (d:any)=>{
                            setChangedData11Custom(d);
                            await onChangedDataUpdateCustom(d, 11);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow11Custom(rows[0])
                        }}
                        buttons={(
                          <>
                            <Button size="sm" onClick={(e:any)=>{
                              setOpenAddDialog11(true);
                            }}>Add Row</Button>
                          </> 
                        )}  
                      />
                    </AntForm>
                    <FormCreateGpaForecastModal
                      options={schoolOptions11}
                      open={openAddDialog11}
                      handleSave={async(values:any) => {
                        setOpenAddDialog11(false);
                        setLoading11(true);
                        try {
                          let seq_no = genSeqNoDetails(gpaForecast11Custom);
                          await gpaForecastDetailServices.createGpaForeCastDetail({
                            gpa_forecast_id: Number(values.gpa_forecast_id),
                            student_id: studentId,
                            is_custom: true,
                            is_override: false,
                            seq_no: seq_no,
                            school_name: values.school_name,
                            summer_course: false,
                            course: values.course,
                            term: values.term,
                            grade: values.grade,
                            credit: values.credit,
                            is_weight_point: values.is_weight_point,
                            unweighted_point: Number(values.unweighted_point ?? 0),
                            weighted_point: Number(values.weighted_point ?? 0),
                          });
                          updateCalcAllData();
                          await refreshGpa();
                        }
                        catch(ex){
                          console.log('Error:',ex);
                        }
                        setLoading11(false);
                      }}
                      onClose={() => {
                        setOpenAddDialog11(false);
                      }}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                    <SumarryTable
                      column={[
                        { label: '', value: 'grade', fontsize5: true},
                        { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                        { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                      ]}
                      data = {gpaForecast11Average}
                      minWidth={120}
                     />
                    </div>
                  </div>
                  <hr />

                  <div className="col-md-12 py-4">
                    <h2>Grade 12th</h2>
                  </div>
                  <div className="col-md-12">
                  <AntForm form={form12} >
                    <EditableTable
                      loading={loading12}
                      cols={grade12_columns}
                      data={gpaForecast12Data}
                      showToolbar={false}
                      showAddBtn={false}
                      showHeader={false}
                      showTopPager={false}
                      showBottomPager={false}
                      showSelectRecord={false}
                      currentPage={currentPage12Data}
                      onChangedDataUpdate={async (d:any)=>{
                          setChangedData12Data(d);
                          await onChangedDataUpdateData(d, 12);
                          await refreshGpa();
                      }}
                      onSelectRow={(rows:any)=>{
                        setCurrentRow12Data(rows[0])
                      }}
                    />
                  </AntForm>
                  </div>
                  <div className="col-md-12 py-3">
                    <h3 className="fs-6">Grade 12th - Custom Data</h3>
                    <AntForm form={form12Custom} >
                      <EditableTable
                        loading={loading12}
                        cols={grade12_custom_columns}
                        data={gpaForecast12Custom}
                        showToolbar={false}
                        showAddBtn={false}
                        showHeader={false}
                        showTopPager={false}
                        showBottomPager={false}
                        showSelectRecord={false}
                        showDeleteButton={true}
                        currentPage={currentPage12Custom}
                        onChangedDataUpdate={async (d:any)=>{
                            console.log('onChangedDataUpdate gpaForecast12Custom :', d);
                            setChangedData12Custom(d);
                            await onChangedDataUpdateCustom(d, 12);
                            await refreshGpa();
                        }}
                        onSelectRow={(rows:any)=>{
                          setCurrentRow12Custom(rows[0])
                        }}
                        buttons={(
                          <>
                            <Button size="sm" onClick={(e:any)=>{
                              setOpenAddDialog12(true);
                            }}>Add Row</Button>
                          </> 
                        )}  
                      />
                    </AntForm>
                    <FormCreateGpaForecastModal
                      options={schoolOptions12}
                      open={openAddDialog12}
                      handleSave={async(values:any) => {
                        setOpenAddDialog12(false);
                        setLoading12(true);
                        try {
                          let seq_no = genSeqNoDetails(gpaForecast12Custom);
                          let ret = await gpaForecastDetailServices.createGpaForeCastDetail({
                            gpa_forecast_id: Number(values.gpa_forecast_id),
                            student_id: studentId,
                            is_custom: true,
                            is_override: false,
                            seq_no: seq_no,
                            school_name: values.school_name,
                            summer_course: false,
                            course: values.course,
                            term: values.term,
                            grade: values.grade,
                            credit: values.credit,
                            is_weight_point: values.is_weight_point,
                            unweighted_point: Number(values.unweighted_point ?? 0),
                            weighted_point: Number(values.weighted_point ?? 0),
                          });
                          console.log('createGpaForeCastDetail ret:', ret);
                          updateCalcAllData();
                          await refreshGpa();
                        }
                        catch(ex){
                          console.log('Error:', ex);
                        }
                        setLoading12(false);
                      }}
                      onClose={() => {
                        setOpenAddDialog12(false);
                      }}
                    />
                  </div>
                  <div className="d-flex w-100 justify-content-end pb-4">
                    <div className="d-flex">
                      <SumarryTable
                        column={[
                          { label: '', value: 'grade', fontsize5: true},
                          { label: 'Unweighted Point', value: 'unweighted_point', align: 'center'},
                          { label: 'Weighted point', value: 'weighted_point', align: 'center'},
                        ]}
                        data = {gpaForecast12Average}
                        minWidth={120}
                      />
                    </div>
                  </div>
              </div>                            
              </div>

            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" className="p-2 px-5 mx-4" onClick={() => handleRefresh()}>
                  Refresh Data
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default GpaForecaster;
