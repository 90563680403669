import React from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import CheckboxInput from 'components/input/CheckboxInput';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
interface Props {
  onNextStep: (step: number) => void;
  formik: any;
}

export interface passwordStrengthProps {
  title: string;
  content: string;
  status: string;
}

const FormRegisterFamily = ({ formik, onNextStep }: Props) => {
  const [agreeTerm, setAgreeTerm] = React.useState(false);

  return (
    <>
      <Form>
        <Row className="m-3 p-0">
          <TextInput
            label="Parent/Guardian (1) - First/Given name"
            name="additional_info.parent_first_name"
            values={formik.values.additional_info?.parent_first_name}
            onChange={formik.handleChange}
            countText={20}
            feedback={
               formik.errors?.['additional_info.parent_first_name'] && (
                <span className="text-danger">
                  {formik.errors?.['additional_info.parent_first_name']}
                </span>
              )
            }
          />

          <TextInput
            label="Parent/Guardian (1) - Last Name"
            name="additional_info.parent_last_name"
            values={formik.values.additional_info?.parent_last_name}
            onChange={formik.handleChange}
            countText={20}
            feedback={
               formik.errors?.['additional_info.parent_last_name'] && (
                <span className="text-danger">
                  {formik.errors?.['additional_info.parent_last_name']}
                </span>
              )
            }
          />
          <SelectInput
            form={formik}
            option={[
              { value: 'Father' },
              { value: 'Mother' },
              { value: 'Brother' },
              { value: 'Sister' },
              { value: 'Grandfather' },
              { value: 'Grandmother' },
              { value: 'Step Father' },
              { value: 'Step Mother' },
              { value: 'Legal Guardian' },
              { value: 'Other' },
            ]}
            label="Relationship to you"
            name="additional_info.relationship_to_you"
            values={formik.values.additional_info?.relationship_to_you}
            onChange={formik.handleChange}
          />
          <TextInput
            countText={36}
            className="common-coallition-flag end-2-flag"
            label="Email address"
            name="additional_info.prefered_email"
            values={formik.values.additional_info?.prefered_email}
            onChange={formik.handleChange}
            feedback={
               formik.errors?.['additional_info.prefered_email'] ? (
                <span className="text-danger">
                  {formik.errors?.['additional_info.prefered_email']}
                </span>
              ) : null
            }
          />
        </Row>

        <div className="mx-3 mt-3 text-field-light">
          Can we communicate with your parent?
          <br />
          If you check this option, your parent may receive information about admissions tips, updates, and reminders from GradMap.
        </div>
        <div className="mx-3">
          <CheckboxInput
            mdCol={'6'}
            label={
              // <span>
              //   By creating an account you agree with our
              //   <a
              //     className="p-0 mx-1 pb-1"
              //     href="#/public/privacy-policies"
              //     target="_blank"
              //     style={{ textDecoration: 'none' }}
              //   >
              //     terms and conditions.
              //   </a>
              // </span>
              <span>I authorize GradMap to communicate with my parent.</span>
            }
            name={'additional_info.accept_contact'}
            values={agreeTerm}
            onChange={(e) => {
              setAgreeTerm(e.target.checked);
              formik.setFieldValue('additional_info.accept_contact', e.target.checked);
            }}
          />
        </div>
        <div className="mx-3 mb-5">
          <Button
            // disabled={!agreeTerm}
            className="w-100 p-2 my-3"
            variant="primary"
            onClick={() => {
              onNextStep(3);
            }}
          >
            <span className="h5 fw-bold">Next</span>
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormRegisterFamily;
