import React from 'react';
import { Card, Stack, Tab, Tabs, Typography, Box } from '@mui/material';
import CustomTabPanel from './TabPanel';
import { useParams } from 'react-router-dom';
import ItemContent, { CheckboxContent } from 'screen/summary/components/ItemContent';
import { buildSexValue } from 'screen/summary/PersonalSum';
import {
  checkEnableParseJSON,
  formatDate,
  renderDate,
  formatBoolean,
} from 'components/helper/helper';
import { Button, Col, Row } from 'react-bootstrap';
import basicInformationServices from 'services/basicInformationApi';
import LoadingContent from 'components/LoadingContent';
import { militaryOptions } from 'screen/personal/demographics/Demographics';
import EthnicitySum from 'screen/summary/components/EthnicitySum';
import { getEducationalProgram } from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { ModalCheckField } from 'screen/personal/basicInformation/ModalCheckField';

export const moduleName: any = {
  '1.1': 'Basic Info & Contact',
  '1.2': 'Demographics',
  '1.3': 'Citizenship',
  '1.4': 'Educational Programs Participation',
  '1.5': 'Other Information',
  '2.1': 'Household',
  '2.2': 'Parent 1',
  '2.3': 'Parent 2',
  '2.4': 'Siblings',
  '3.1': 'High Schools',
  '3.2': 'Colleges & Universities',
  '3.3': 'Future Plans',
  '4.1': 'General',
  '4.2': 'SAT & ACT',
  '4.3': 'AP Tests',
  '4.4': 'IB Tests',
  '4.5': 'CLEP Tests',
  '5.1': 'General',
  '5.2': '9th',
  '5.3': '10th',
  '5.4': '11th',
  '5.5': '12th',
  '5.6': 'College Courses',
  '5.7': 'A-G Summary',
  '5.8': 'W Course Summary',
  '6.1': 'Activities 1-10',
  '6.2': 'Honors & Awards',
  '6.3': 'EOP and Other Info',
  '6.6': 'Activities 20+',
  '6.5': 'Activities 11-20',
  '7.1': 'General',
  '7.2': 'Common App',
  '7.3': 'UC App',
  '8.1': 'College Search',
  '8.2': 'College List',
  '9.1': 'GPA Forecaster',
};

export const moduleLink: any = {
  '1.1': window.location.origin + '/#/application/summary/personal/',
  '1.2': window.location.origin + '/#/application/summary/personal/',
  '1.3': window.location.origin + '/#/application/summary/personal/',
  '1.4': window.location.origin + '/#/application/summary/personal/',
  '1.5': window.location.origin + '/#/application/summary/personal/',
  '2.1': window.location.origin + '/#/application/summary/family/',
  '2.2': window.location.origin + '/#/application/summary/family/',
  '2.3': window.location.origin + '/#/application/summary/family/',
  '2.4': window.location.origin + '/#/application/summary/family/',
  '3.1': window.location.origin + '/#/application/summary/education/',
  '3.2': window.location.origin + '/#/application/summary/education/',
  '3.3': window.location.origin + '/#/application/summary/education/',
  '4.1': window.location.origin + '/#/application/summary/test/',
  '4.2': window.location.origin + '/#/application/summary/test/',
  '4.3': window.location.origin + '/#/application/summary/test/',
  '4.4': window.location.origin + '/#/application/summary/test/',
  '4.5': window.location.origin + '/#/application/summary/test/',
  '5.1': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.2': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.3': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.4': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.5': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.6': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.7': window.location.origin + '/#/application/summary/course-and-grade/',
  '5.8': window.location.origin + '/#/application/summary/course-and-grade/',
  '6.1': window.location.origin + '/#/application/summary/activities-and-honors/',
  '6.2': window.location.origin + '/#/application/summary/activities-and-honors/',
  '6.3': window.location.origin + '/#/application/summary/activities-and-honors/',
  '6.6': window.location.origin + '/#/application/summary/activities-and-honors/',
  '6.5': window.location.origin + '/#/application/summary/activities-and-honors/',
  '7.1': window.location.origin + '/#/application/summary/additional/',
  '7.2': window.location.origin + '/#/application/summary/additional/',
  '7.3': window.location.origin + '/#/application/summary/additional/',
  '8.1': window.location.origin + '/#/application/college-search',
  '8.2': window.location.origin + '/#/application/college-search',
  '9.1': window.location.origin + '/#/application/summary/GPAForecasterSum',
};

export const agSubjectTotals: any = {
  A: 'History / Social Science (2 Years)',
  B: 'English (4 Years)',
  C: 'Mathematics (3 Years)',
  D1: 'Biological Science (1 Year)',
  D2: 'Physical Science (1 Year)',
  E: 'Language Other Than English (2 Years)',
  F: 'Visual & Performing Arts (1 Year)',
  G: 'College-Preparatory Elective (1 Year)',
};

export const wCourseSummary: any = {
  WH: 'Weighted Honors Courses',
  AP: 'AP - Advanced Placement Courses',
  IB: 'IB - International Baccalaureate Courses',
  TC: 'Transferable College Courses',
};

type Props = {
  setCurrentModuleId: (id: string) => void;
  isCounselor?: boolean;
};

export const buildClassName = (moduleName: string, checkModule: any) => {
  let classNameResult = '';

  if (checkModule?.[moduleName]?.[0]) {
    classNameResult = classNameResult + ' student-checked';
  }
  if (checkModule?.[moduleName]?.[1]) {
    classNameResult = classNameResult + ' counselor-checked';
  }
  return classNameResult;
};

const RPersonalInformation = ({ setCurrentModuleId, isCounselor }: Props) => {
  const [value, setValue] = React.useState(1);
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`1.${newValue}`);
  };
  const [showModalCheck, setShowModelCheck] = React.useState(false);

  const handleCloseModalCheck = () => {
    setShowModelCheck(false);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab
          value={1}
          label="Basic Info & Contact"
          className={buildClassName('1.1', checkModule)}
        />
        <Tab value={2} label="Demographics" className={buildClassName('1.2', checkModule)} />
        <Tab
          value={3}
          label="Citizenship/Residency"
          className={buildClassName('1.3', checkModule)}
        />
        <Tab value={4} label="CBOs & Fee Waiver" className={buildClassName('1.4', checkModule)} />
        <Tab value={5} label="Other Information" className={buildClassName('1.5', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <div className="d-flex justify-content-between mb-4">
            <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
              Basic Info & Contact
            </Typography>
            <div>
              <Button
                variant="primary"
                className="p-2 px-3"
                onClick={() => setShowModelCheck(true)}
              >
                Check missing values
              </Button>
            </div>
          </div>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryBasicInfo show={showModalCheck} handleCloseModalCheck={handleCloseModalCheck} />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Demographics
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryDemographics />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Citizenship
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryCitizenship />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Educational Programs Participation
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryCBO />
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          {/* <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Other Information
          </Typography> */}
          <Stack justifyContent={'center'} alignItems={'center'}>
            <SummaryOther />
          </Stack>
        </Card>
      </CustomTabPanel>
    </Box>
  );
};

export default RPersonalInformation;

export const SummaryBasicInfo = ({
  show,
  handleCloseModalCheck,
}: {
  show?: boolean;
  handleCloseModalCheck: () => void;
}) => {
  const [data, setData] = React.useState<any>(null);
  const [contactData, setContactData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [res, contactResponse]: [any, any] = await Promise.all([
        basicInformationServices.getBasicDetail(params.id === ':id' ? null : params.id),
        basicInformationServices.getContactDetail(params.id === ':id' ? null : params.id),
      ]);
      setData({ ...res, csu_info: checkEnableParseJSON(res?.csu_info)});
      setContactData(contactResponse);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <Row className="w-100">
        <ItemContent label="First/given name*" values={data?.first_name} />
        <ItemContent label="Middle name" values={data?.middle_name} />
        <ItemContent label="Last/family/surname*" values={data?.last_name} />
        <ItemContent label="Sufix" values={data?.label} />

        <Col md={6}>
          <ItemContent mdCol={12} label="Email*" values={data?.email} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="Email Address Type*" values={data?.csu_info?.emailType} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="Date of Birth*" values={data?.dob} />
        </Col>

        {/* <ItemContent label="Prefered Name (Nickname)" values={data?.nick_name} /> */}

        <ItemContent
          mdCol={6}
          label="Do you have a lived /preferred name?*"
          values={data?.csu_info?.have_lived_name}
        />

        {formatBoolean(data?.csu_info?.have_lived_name) === true && (
          <>
            <ItemContent label="Lived first name *" values={data?.csu_info?.lived_first_name} />
            <ItemContent label="Lived middle name" values={data?.csu_info?.lived_middle_name} />
            <ItemContent
              label="Lived Last / family / surname *"
              values={data?.csu_info?.lived_last_name}
            />
          </>
        )}
        <Col md={6}>
          <ItemContent
            label="Have you ever had a different legal name?"
            values={data?.is_have_legal_name}
            mdCol={12}
          />
        </Col>

        {data?.is_have_legal_name && (
          <>
            <Col md={3}>
              <ItemContent mdCol={12} label="Former First Name *" values={data?.legal_first_name} />
            </Col>
            <ItemContent label="Former Middle Name" values={data?.legal_middle_name} />
            <ItemContent label="Former Last Name *" values={data?.legal_family_name} />
            <ItemContent label="Former Suffix" values={data?.legal_first_name_you_go_by} />
          </>
        )}
        <div className="border w-100 my-3" />
        <div className="fs-5 text-field-light">Gender and Sexual Orientation</div>
        <Col md={3}>
          <ItemContent
            mdCol={12}
            label="Sex*"
            values={buildSexValue(checkEnableParseJSON(data?.sex))}
          />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="Legal sex*" values={data?.csu_info?.legal_sex} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="Pronouns" values={buildSexValue(data, true)} />
        </Col>
        <div />
        {/* <ItemContent label="Gender" values={data?.gender_identity_description} /> */}
        <Col md={3}>
          <ItemContent
            mdCol={12}
            label="How do you describle yourself?*"
            values={data?.sex_self_describe}
          />
        </Col>

        <Col md={3}>
          <ItemContent
            mdCol={12}
            label="What do you consider to be?*"
            values={data?.sex_self_consider}
          />
          {data?.sex_self_consider === 'Another Orientation' && (
            <ItemContent
              mdCol={12}
              label="Please describe"
              values={data?.sex_self_consider_describe}
            />
          )}
        </Col>

        <ItemContent
          values={data?.csu_info?.gender_identity}
          label="How do you describe the way you express your gender identity in terms of behavior, appearance, speech, and movement?*"
        />
        <ItemContent
          hidden={data?.csu_info?.gender_identity !== 'None of the Above'}
          label="Another identity expression (please specify)*"
          values={data?.csu_info?.another_gender_identity}
        />
        <div className="border w-100 my-3" />
        <div className="fs-5 text-dark">Address</div>
        <ItemContent
          mdCol={6}
          label={'Address Line 1 (including Apartment or Suite Number)*'}
          values={data?.address_line_1}
        />
        <ItemContent mdCol={6} label={'Address Line 2'} values={data?.address_line_2} />

        <Col md={3}>
          <ItemContent mdCol={12} label="City" values={data?.city} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="State" values={data?.state} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="County*" values={data?.csu_info?.address_county} />
        </Col>
        <Col md={3}>
          <ItemContent mdCol={12} label="Country*" values={data?.country} />
        </Col>

        <ItemContent mdCol={3} label="Zip Code" values={data?.zip_code} />
        <ItemContent
          mdCol={9}
          label="Approximate date through which current address is valid"
          values={formatDate(data?.csu_info?.approximate_date)}
        />

        <div className="px-2 pt-2 text-field-light fw-bold">
          Is this your permanent address and where we should send mail?
        </div>
        <ItemContent
          mdCol={12}
          label="UC's need your current mailing address to send mail correspondences about the admissions process"
          values={data?.is_should_send_mail}
        />
        <div className="px-2 pt-2 text-field-light fw-bold">
          Do you have an alternate mailing address?{' '}
        </div>
        <ItemContent
          mdCol={12}
          label=" If you attend a boarding school or use a different mailing address"
          values={data?.is_have_alternate_address}
        />
        {formatBoolean(data?.is_have_alternate_address) === true && (
          <>
            <div className="px-2 pt-2 text-field-light fw-bold">Mailing Address</div>
            <Col md={3}>
              <ItemContent
                mdCol={12}
                label={'Address Line 1 (including Apartment or Suite Number)'}
                values={data?.alternate_address_line_1 ?? data?.address_line_1}
              />
            </Col>
            <Col md={3}>
              <ItemContent
                mdCol={12}
                label={'Address Line 2'}
                values={data?.alternate_address_line_2 ?? data?.address_line_2}
              />
            </Col>
            <Col md={3}>
              <ItemContent mdCol={12} label={'City*'} values={data?.alternate_city ?? data?.city} />
            </Col>
            <Col md={3}>
              <ItemContent
                mdCol={12}
                label={'State'}
                values={data?.alternate_state ?? data?.state}
              />
            </Col>

            <ItemContent label={'Zip Code'} values={data?.alternate_zip_code ?? data?.zip_code} />
            <ItemContent
              label={'County*'}
              values={data?.csu_info?.mailing_address_county ?? data?.country}
            />
            <ItemContent
              label="Is this a temporary address?"
              mdCol={12}
              values={data?.is_temporary_address}
            />

            {formatBoolean(data?.is_temporary_address) === true && (
              <>
                <ItemContent label="From date" values={data?.temporary_from_date} />

                <ItemContent label="To date" values={data?.temporary_to_date} />
              </>
            )}
          </>
        )}

        <div className="border w-100 my-3" />
        {contactData && (
          <>
            <div className="fs-5 text-dark">Contact Details</div>
            <ItemContent mdCol={4} label="Main Phone Number" values={contactData?.phone_number} />
            <ItemContent
              mdCol={4}
              label="I have an international phone number"
              values={contactData?.is_have_international_phone_number}
            />
            <ItemContent mdCol={4} label="Type of Number" values={contactData?.phone_number_type} />

            <ItemContent
              label="Secondary Phone Number"
              values={contactData?.alternative_phone_number}
              mdCol={4}
            />
            <ItemContent
              mdCol={4}
              label="I have an international phone number"
              values={contactData?.alternative_is_have_international_phone_number}
            />
            <ItemContent
              mdCol={4}
              label="Type of Number"
              values={contactData?.alternative_phone_number_type}
            />
            <CheckboxContent
              label={
                'I authorize text messages (for example, reminders about application deadlines) to be sent to the above phone number and accept responsibility for any charges incurred.'
              }
              values={contactData?.is_authorized_text_message_sent}
            />

            <CheckboxContent
              label={
                'I agree to the Terms of Service and to receive calls and/or texts at any phone number I have provided or may provide in the future, including any wireless number, from any entity associated with my application process, including but not limited to my designated schools and programs, the Liaison International support team, the association or institution for this Centralized Application Service.'
              }
              values={contactData?.is_agree_csu_term}
            />
            <ItemContent
              label="Can we share your contact information with UC campus and programs?"
              values={contactData?.is_allowed_share_contact}
              mdCol={12}
            />
            <CheckboxContent
              label={'I authorize the University of California to share my contact information.'}
              values={contactData?.is_allowed_share_contact}
            />
          </>
        )}
      </Row>

      {data && <ModalCheckField show={show} onClose={handleCloseModalCheck} data={{...data, sex: checkEnableParseJSON(data?.sex)}} />}
    </>
  );
};

export const SummaryDemographics = () => {
  const [demographicData, setDemographicData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await basicInformationServices.getDemoGraphics(
        params.id === ':id' ? null : params.id
      );
      setDemographicData({
        ...res,
        demographics_additional_data: checkEnableParseJSON(res?.demographics_additional_data),
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {demographicData && (
        <>
          <div className="mt-3">Check all that apply to you: *</div>
          {militaryOptions.map((option, index) => {
            const keyName = option.value;
            return (
              <CheckboxContent
                key={index}
                label={option.label}
                values={checkEnableParseJSON(demographicData?.all_apply_array, {})[keyName]}
              />
            );
          })}

          {demographicData.is_foster_care && (
            <ItemContent
              label="Are you a ward of the court or in foster care in California? *"
              values={demographicData?.demographics_additional_data?.isInFosterCare}
              mdCol={12}
            />
          )}

          <div className="fs-5 text-field-light mt-3">Military Affilliation</div>
          <ItemContent
            label="U.S. Armed Forces Status"
            values={demographicData?.us_armed_forces_status}
            mdCol={6}
          />

          {demographicData.us_armed_forces_status !== 'None' && (
            <ItemContent
              label="Are you a dependent of a U.S. military veteran or service member?"
              values={demographicData?.is_dependent_us_military}
              mdCol={12}
            />
          )}
          {(demographicData.us_armed_forces_status === 'Currently Serving' ||
            demographicData.us_armed_forces_status === 'Previously Served') && (
            <>
              <div className="fs-5 p-1 fw-bold my-2">Military experience</div>
              <ItemContent
                label="Anticipated status at the time you enroll"
                mdCol={12}
                values={demographicData.anticipated_status}
              />
              {demographicData.anticipated_status === 'On Active Duty' && (
                <ItemContent
                  label="Are you permanently stationed in California? *"
                  values={demographicData.demographics_additional_data?.isPermanentlyStationed}
                  mdCol={12}
                />
              )}

              <ItemContent
                label="Anticipated status at the time you enroll"
                values={demographicData?.anticipated_status}
                mdCol={12}
              />
              <div className="fs-5 text-field-light">Military experience</div>
              <ItemContent
                label="Number of Military experience you have"
                values={demographicData?.number_military_experience}
                mdCol={12}
              />
              {(checkEnableParseJSON(demographicData?.military_experience_array) || []).map(
                (item: any, index: number) => (
                  <ItemContent
                    key={index}
                    label="Branch"
                    values={
                      item?.branch +
                      ' (' +
                      renderDate(item.startDate) +
                      ' - ' +
                      renderDate(item.endDate) +
                      ')'
                    }
                    mdCol={12}
                  />
                )
              )}
            </>
          )}

          {demographicData?.us_armed_forces_status === 'Current Dependent' && (
            <>
              <ItemContent
                mdCol={12}
                label="What is the status of the person you are a dependent of who is currently serving or has previously served?"
                values={
                  demographicData?.demographics_additional_data?.statusOfPersonYouAreDependance
                }
              />
              <ItemContent
                mdCol={12}
                label="Is the U.S. military service member on active duty? *"
                values={
                  demographicData?.demographics_additional_data
                    ?.is_US_military_service_on_active_duty
                }
              />
              <ItemContent
                mdCol={12}
                label="Is the U.S. military service member permanently stationed in California? *"
                values={
                  demographicData?.demographics_additional_data
                    ?.is_US_military_service_permanently_stationed_in_California
                }
              />
              <CheckboxContent
                values={
                  demographicData?.demographics_additional_data
                    ?.is_plan_to_submit_us_military_transcript
                }
                label="Check if you plan to submit an official U.S. military transcript (AARTS, SMART, JST or CGI)?"
              />
            </>
          )}
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Ethnicity</div>
          <ItemContent
            mdCol={12}
            label="Do you consider yourself Hispanic or Latino?"
            values={demographicData?.is_consider_hispanic_latino}
          />

          {demographicData?.is_consider_hispanic_latino && (
            <ItemContent
              mdCol={12}
              label="Which best describes your Hispanic or Latino background?"
              values={
                JSON.parse(demographicData?.best_group_describe_racial_background_array)
                  ?.value_consider_hispanic_latino || ''
              }
            />
          )}

          <EthnicitySum formValues={demographicData} />
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Language</div>
          {/* <ItemContent label="Language" values={demographicData?.language} mdCol={6} />
          <ItemContent
            label="Specify other language"
            values={demographicData?.other_religion_language}
            mdCol={6}
          /> */}
          <ItemContent
            label="Number of languages you are proficient in*"
            values={demographicData?.number_language_proficient}
            mdCol={6}
          />
          <ItemContent
            label="Proficiency Level"
            values={checkEnableParseJSON(demographicData?.language_array, []).map((i: any) =>
              buildLanguage(i)
            )}
            mdCol={6}
          />
        </>
      )}
    </Row>
  );
};
const buildLanguage = (i: any) => {
  const proficiency = [
    i.isFirstLanguage ? 'First Language' : '',
    i.isSpeak ? 'Speak' : '',
    i.isRead ? 'Read' : '',
    i.isWrite ? 'Write' : '',
    i.isSpokenAtHome ? 'Spoken at Home' : '',
  ]
    .filter((i) => i !== '')
    .join(', ');
  return `${i.language} (${i.proficiencyLevel} ${
    proficiency?.length > 0 ? '- ' + proficiency : ''
  })`;
};
export const SummaryCitizenship = () => {
  const [citizenshipData, setCitizenshipData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await basicInformationServices.getCitizenship(
        params.id === ':id' ? null : params.id
      );
      setCitizenshipData({
        ...res,
        csu_info: checkEnableParseJSON(res?.csu_info),
        statement_legal_resident: checkEnableParseJSON(res?.statement_legal_resident),
        indicator_economic_fee_waiver_array: checkEnableParseJSON(
          res?.indicator_economic_fee_waiver_array
        ),
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {citizenshipData && (
        <>
          <ItemContent
            label="Citizenship status"
            values={citizenshipData?.current_citizenship_status}
            mdCol={3}
          />
          {/* <ItemContent
            label="Is U.S. Dual Citizen?"
            values={citizenshipData?.is_dual_citizenship}
            mdCol={6}
          /> */}
          <ItemContent
            label="List Citizenship(s)"
            values={(checkEnableParseJSON(citizenshipData?.list_citizenship) || []).map(
              (item: any) => item
            )}
            mdCol={3}
          />
          {/* <ItemContent
            label="Main Country of Citizenship"
            values={citizenshipData?.country}
            mdCol={3}
          /> */}
          <div className="px-2 pt-2 text-field-light fw-bold">International Agent</div>
          <ItemContent
            label="Are you currently working with a representative or agency?"
            mdCol={12}
            values={citizenshipData?.csu_info?.current_working_with_representative_or_agency}
          />
          {formatBoolean(
            citizenshipData?.csu_info?.current_working_with_representative_or_agency
          ) === true && (
            <>
              <ItemContent
                label="Representative Type"
                values={citizenshipData?.csu_info?.representative_type}
              />
              <ItemContent
                label="Representative Name"
                values={citizenshipData?.csu_info?.representative_name}
              />
              <ItemContent
                label="Representative Street Address"
                values={citizenshipData?.csu_info?.representative_street_address}
              />
              <ItemContent
                label="Representative Street Address Line 2"
                values={citizenshipData?.csu_info?.representative_street_address_line_2}
              />
              <ItemContent
                label="Representative City"
                values={citizenshipData?.csu_info?.representative_city}
              />
              <ItemContent
                label="Representative State/Province"
                values={citizenshipData?.csu_info?.representative_state}
              />
              <ItemContent
                label="Representative Postal Code"
                values={citizenshipData?.csu_info?.representative_postal_code}
              />
              <ItemContent
                label="Representative Country"
                values={citizenshipData?.csu_info?.representative_country}
              />
              <ItemContent
                label="Representative Phone"
                values={citizenshipData?.csu_info?.representative_phone}
              />
              <ItemContent
                mdCol={6}
                label="Representative Email"
                values={citizenshipData?.csu_info?.representative_email}
              />
            </>
          )}

          <div className="px-2 pt-2 text-field-light fw-bold">International Applicant Details</div>
          <ItemContent
            label="What year did you (or do you plan to) move to the U.S.?"
            mdCol={6}
            values={citizenshipData?.csu_info?.year_move_to_US}
          />
          <ItemContent
            label="What is your main country of Citizenship ?"
            values={citizenshipData?.country}
            mdCol={6}
          />

          {citizenshipData?.country !== 'United States of America' && (
            <>
              <ItemContent
                label="What will your citizenship status be on the date you submit this application? *"
                values={citizenshipData?.citizenship_status}
                mdCol={12}
              />
              {citizenshipData?.citizenship_status ===
                'Legal U.S. permanent resident (green card)' && (
                <>
                  <ItemContent
                    label="Country of Citizenship"
                    values={citizenshipData?.csu_info?.country}
                  />
                  <ItemContent
                    label="Permanent Resident / Green Card Date Issued"
                    values={formatDate(citizenshipData?.csu_info?.date)}
                  />
                </>
              )}
              {citizenshipData?.citizenship_status === 'Refugee' && (
                <ItemContent
                  label="Country of Citizenship"
                  values={citizenshipData?.csu_info?.country}
                />
              )}
              {citizenshipData?.citizenship_status ===
                'Non-immigrant/foreign visa (you have a visa or will be applying for one)' && (
                <>
                  <ItemContent
                    mdCol={12}
                    values={citizenshipData?.have_us_visa}
                    label="Do you currently have a U.S. visa? *"
                  />
                  <div />
                  {formatBoolean(citizenshipData?.have_us_visa) === false && (
                    <>
                      <ItemContent
                        mdCol={12}
                        label="Which type of U.S. visa will you apply for (or have applied for)? *"
                        values={citizenshipData?.type_of_apply_for_us_visa}
                      />
                      <ItemContent
                        mdCol={12}
                        label="Country of Citizenship"
                        values={citizenshipData?.csu_info?.country}
                      />
                    </>
                  )}
                  {formatBoolean(citizenshipData?.have_us_visa) === true && (
                    <>
                      <ItemContent
                        mdCol={12}
                        label="Current visa type *"
                        values={citizenshipData?.type_of_current_us_visa}
                      />

                      <ItemContent
                        mdCol={12}
                        values={citizenshipData?.csu_info?.require_F1_Visa_or_J1_visa}
                        label="Do you have or will you require an F1 Visa (student) or J1 Visa (exchange) to study at the California State University (CSU)?"
                      />

                      {formatBoolean(citizenshipData?.csu_info?.require_F1_Visa_or_J1_visa) ===
                      true ? (
                        <>
                          <ItemContent
                            mdCol={6}
                            values={citizenshipData?.csu_info?.visa_you_have_at_CSU}
                            label="Which student visa do you have, or will you have when you study at the CSU?"
                          />
                          <ItemContent
                            mdCol={6}
                            label="Date Issued (if you have already been issued a student visa)"
                            values={formatDate(
                              citizenshipData?.csu_info?.visa_you_have_at_CSU_date_issued
                            )}
                          />
                        </>
                      ) : (
                        <ItemContent
                          mdCol={12}
                          label="What U.S. State/Possession do you regard as your permanent home?"
                          values={citizenshipData?.csu_info?.us_state_you_regard_as_permanent_home}
                        />
                      )}
                    </>
                  )}
                  <div />
                  <ItemContent
                    label="Visa Date Issued"
                    values={formatDate(citizenshipData?.csu_info?.visa_date_issued)}
                  />

                  <ItemContent
                    values={citizenshipData?.will_you_have_different_visa}
                    label="By the time you attend UC, will you have a different visa?"
                  />
                  {formatBoolean(citizenshipData?.will_you_have_different_visa) === true && (
                    <ItemContent
                      label="Planned visa type *"
                      values={citizenshipData?.planned_visa_type}
                    />
                  )}
                </>
              )}
            </>
          )}

          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Social Security Number</div>
          <ItemContent
            mdCol={12}
            label="Do you have a U.S. Social Security number or an Individual Taxpayer Identification Number (ITIN)?"
            values={citizenshipData?.is_have_us_social_security_number}
          />
          <div className="text-field-light m-2">
            To protect your privacy, we do not ask for your social security number, however you'll
            need to input this information directly on the application when it is requested.
          </div>
          {/*--------------------------------------------------------------------------------------------------------------------------------------*/}
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Birthplace</div>
          <ItemContent
            label="Country / Region / Territory"
            mdCol={6}
            values={citizenshipData?.country_birthplace}
          />
          <ItemContent
            label="Number of years you have lived in the United States*"
            values={citizenshipData?.number_of_year_in_us}
            mdCol={6}
          />
          <ItemContent label="City of birth*" values={citizenshipData?.city_birthplace} mdCol={6} />

          {citizenshipData?.country_birthplace === 'United States of America' && (
            <ItemContent label="State" values={citizenshipData?.state_birthplace} mdCol={6} />
          )}

          <ItemContent
            label="Have you lived in California continuously since birth?"
            values={
              citizenshipData?.statement_legal_resident
                ?.have_lived_in_California_continuously_since_birth
            }
            mdCol={6}
          />
          {citizenshipData?.statement_legal_resident
            ?.have_lived_in_California_continuously_since_birth === 'false' && (
            <ItemContent
              label="When did your present stay in California begin?"
              values={renderDate(
                citizenshipData?.statement_legal_resident
                  ?.when_your_present_stay_in_California_begin
              )}
              mdCol={6}
            />
          )}

          {citizenshipData?.country_birthplace !== 'United States of America' && (
            <>
              <div className="px-2 pt-2 text-field-light fw-bold">
                Years inside and outside the United States
              </div>
              <ItemContent
                label="If you were born outside of the U.S., what year did you or will you move to the U.S."
                values={citizenshipData?.csu_info?.year_move_to_us}
                mdCol={6}
              />
              <ItemContent
                label="Number of years you have lived outside the United States*"
                mdCol={6}
                values={citizenshipData?.number_of_year_outside_us}
              />
            </>
          )}

          {/*--------------------------------------------------------------------------------------------------------------------------------------*/}
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">California residency for admission purposes</div>
          <ItemContent
            label="By the time you attend UC, will you have graduated from a California high school?"
            values={citizenshipData?.is_graduated_california_high_school}
            mdCol={12}
          />

          {formatBoolean(citizenshipData?.is_graduated_california_high_school) === true && (
            <ItemContent
              label="By the time you attend UC, will you have attended a California school for three or more years during grades K through 12?"
              values={citizenshipData?.is_three_or_more_year_graduated_california_high_school}
              mdCol={12}
            />
          )}

          {formatBoolean(
            citizenshipData?.is_three_or_more_year_graduated_california_high_school
          ) === false && (
            <>
              <ItemContent
                label="Are you currently living in California?"
                values={citizenshipData?.is_currently_live_california}
                mdCol={6}
              />
              {formatBoolean(citizenshipData?.is_currently_live_california) === true && (
                <>
                  <ItemContent
                    label="Have you lived in California your entire life?"
                    values={citizenshipData?.is_live_california_entire_life}
                    mdCol={6}
                  />
                  {formatBoolean(citizenshipData?.is_live_california_entire_life) === false && (
                    <ItemContent
                      label="When did your present stay in California begin?"
                      values={
                        citizenshipData?.statement_legal_resident
                          ?.when_your_present_stay_in_California_begin
                      }
                      mdCol={6}
                    />
                  )}

                  <div className="px-2 pt-2 text-field-light">I have lived here since:</div>
                  <ItemContent
                    label="Month"
                    values={citizenshipData?.month_live_here_since}
                    mdCol={6}
                  />
                  <ItemContent
                    label="Year"
                    values={
                      citizenshipData?.year_live_here_since === 0
                        ? '-'
                        : citizenshipData?.year_live_here_since
                    }
                    mdCol={6}
                  />
                  <ItemContent
                    label="Is one of your parents or legal guardians a permanent resident of California?"
                    values={citizenshipData?.is_one_of_parent_permanent_resident_california}
                    mdCol={12}
                  />
                  <ItemContent
                    label="Is your parent, legal guardian, spouse or registered domestic partner an employee of the University of California or a UC-affiliated national laboratory (Los Alamos National Security LLC or Lawrence Livermore National Security LLC)?*"
                    values={citizenshipData?.is_parent_employee_university_california}
                    mdCol={12}
                  />
                  {formatBoolean(
                    citizenshipData?.is_one_of_parent_permanent_resident_california
                  ) === true && (
                    <>
                      <div className="px-2 pt-2 text-field-light">
                        When did your parent or legal guardian become a permanent resident of
                        California? *
                      </div>
                      <ItemContent
                        label="Month"
                        values={citizenshipData?.month_parent_become_permanent_residence}
                        mdCol={6}
                      />
                      <ItemContent
                        label="Year"
                        values={
                          citizenshipData?.year_parent_become_permanent_residence === 0
                            ? '-'
                            : citizenshipData?.year_parent_become_permanent_residence
                        }
                        mdCol={6}
                      />
                    </>
                  )}
                  {formatBoolean(
                    citizenshipData?.is_one_of_parent_permanent_resident_california
                  ) === false && (
                    <ItemContent
                      label="Which U.S State/possession does your parent/guardian regard as her permanent home ?"
                      values={
                        citizenshipData?.csu_info?.state_your_parent_as_her_permanent_home ?? '-'
                      }
                      mdCol={12}
                    />
                  )}
                </>
              )}
            </>
          )}

          {/*--------------------------------------------------------------------------------------------------------------------------------------*/}
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Statement of Legal Residence</div>
          <ItemContent
            values={
              citizenshipData?.statement_legal_resident
                ?.is_like_to_be_evaluated_for_tuition_purposes
            }
            label="Would you like to be evaluated for California residency for tuition purposes? *"
            mdCol={12}
          />
          {formatBoolean(
            citizenshipData?.statement_legal_resident?.is_like_to_be_evaluated_for_tuition_purposes
          ) === true && (
            <>
              <ItemContent
                values={
                  citizenshipData?.statement_legal_resident?.is_physically_present_for_3_years
                }
                label="Have you been physically present in California for the last 3 years? *"
                mdCol={6}
              />

              <ItemContent
                values={
                  citizenshipData?.statement_legal_resident?.is_attend_highschool_for_3_more_years
                }
                label="Did you attend high school in California for three or more years, and will you graduate or have you graduated from a high school in California? *"
                mdCol={6}
              />

              <ItemContent
                values={citizenshipData?.statement_legal_resident?.is_established_as_permanent_home}
                label="Have you and your parents established California as your permanent home for the past 366 days or more? *"
                mdCol={6}
              />

              {citizenshipData?.statement_legal_resident?.is_established_as_permanent_home !==
                'I do not consider myself a dependent upon my parents' && (
                <ItemContent
                  label="Are your parents married to each other?"
                  values={
                    citizenshipData?.statement_legal_resident?.is_parent_1_married_to_parent_2
                  }
                  mdCol={3}
                />
              )}

              {citizenshipData?.statement_legal_resident?.is_parent_1_married_to_parent_2 ===
                'No' && (
                <ItemContent
                  label="Do your parents live together? *"
                  values={citizenshipData?.statement_legal_resident?.is_parents_live_together}
                />
              )}

              <ItemContent
                values={citizenshipData?.statement_legal_resident?.is_parent_1_physically_present}
                label="Has Parent 1 been physically present in California for the last 3 years? *"
                mdCol={6}
              />

              {citizenshipData?.statement_legal_resident?.is_parent_1_physically_present &&
              citizenshipData?.statement_legal_resident?.is_parent_1_physically_present !==
                'My Parent is Deceased' ? (
                <ItemContent
                  values={citizenshipData?.statement_legal_resident?.is_parent_1_US_citizen}
                  label="Is Parent 1 a U.S. Citizen? *"
                  mdCol={6}
                />
              ) : (
                <div className="p-2 d-flex flex-column justify-content-between col-md-6 col-12"></div>
              )}

              {!(
                citizenshipData?.statement_legal_resident?.is_parent_1_married_to_parent_2 ===
                  'No' &&
                citizenshipData?.statement_legal_resident?.is_parents_live_together === 'No'
              ) && (
                <>
                  <ItemContent
                    values={
                      citizenshipData?.statement_legal_resident?.is_parent_2_physically_present
                    }
                    label="Has Parent 2 been physically present in California for the last 3 years? *"
                    mdCol={6}
                  />

                  {citizenshipData?.statement_legal_resident?.is_parent_2_physically_present &&
                  citizenshipData?.statement_legal_resident?.is_parent_2_physically_present !==
                    'My Parent is Deceased' ? (
                    <ItemContent
                      values={citizenshipData?.statement_legal_resident?.is_parent_2_US_citizen}
                      label="Is Parent 2 a U.S. Citizen? *"
                      mdCol={6}
                    />
                  ) : (
                    <div className="p-2 d-flex flex-column justify-content-between col-md-6 col-12"></div>
                  )}
                </>
              )}
            </>
          )}

          <CheckboxContent
            col={12}
            values={citizenshipData?.statement_legal_resident?.is_declare_under_penalty_of_perjury}
            label="I declare under penalty of perjury under the laws governing the State of California that the statements I have provided on this Statement of Legal Residence are, and each of them is, true and correct."
          />
          <CheckboxContent
            col={12}
            values={
              citizenshipData?.statement_legal_resident?.is_understood_to_be_asked_submit_document
            }
            label="I understand that as part of the process to issue a Residency Classification, I may be asked to submit additional documentation or information to a University of California Residence Deputy to supplement my Statement of Legal Residence. Failure to provide the requested information in a timely manner can affect my residence classification. Further, I understand that completion of the Statement of Legal Residence does not guarantee a Resident Classification for UC tuition."
          />
        </>
      )}
    </Row>
  );
};

export const SummaryCBO = () => {
  const [citizenshipData, setCitizenshipData] = React.useState<any>(null);
  const [EducationalPrograms, setEducationalPrograms] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await basicInformationServices.getCitizenship(
        params.id === ':id' ? null : params.id
      );

      setCitizenshipData({
        ...res,
        csu_info: checkEnableParseJSON(res?.csu_info),
        statement_legal_resident: checkEnableParseJSON(res?.statement_legal_resident),
        indicator_economic_fee_waiver_array: checkEnableParseJSON(
          res?.indicator_economic_fee_waiver_array
        ),
      });
      setLoading(false);
    };
    const getEducationalPrograms = async () => {
      try {
        setLoading(true);
        let studentId = params.id === ':id' ? null : params.id;
        let res = await dispatch(getEducationalProgram(studentId)).unwrap();
        const dataLoaded: any = res; // res.payload;
        if (dataLoaded) {
          const values: any = checkEnableParseJSON(dataLoaded.csu_info);
          setEducationalPrograms(values);
        }
      } catch (ex) {
        console.log('Error: ', ex);
      }
      setLoading(false);
    };
    if (params.id !== ':id') {
      getData();
      getEducationalPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      {citizenshipData && (
        <>
          <div className="text-dart">
            Have you participated in any of these programs in high school and beyond?
          </div>
          <Row>
            <ItemContent mdCol={4} label="AVID" values={EducationalPrograms?.avid} />
            {formatBoolean(EducationalPrograms?.avid) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in AVID during High School:"
                values={EducationalPrograms?.year_participated_in_AVID}
              />
            )}
          </Row>
          <Row>
            <ItemContent
              mdCol={4}
              label="Upward Bound"
              values={EducationalPrograms?.upward_bound}
            />
            {formatBoolean(EducationalPrograms?.upward_bound) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in Upward Bound during High School:"
                values={EducationalPrograms?.year_participated_in_upward_bound}
              />
            )}
          </Row>
          <Row>
            <ItemContent
              mdCol={4}
              label="Talent Search or Educational Opportunity Center (EOC)"
              values={EducationalPrograms?.talent_search}
            />
            {formatBoolean(EducationalPrograms?.talent_search) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in Talent Search or Educational Opportunity Center (EOC) during high school"
                values={EducationalPrograms?.year_participated_in_talent_search}
              />
            )}
          </Row>
          <Row>
            <ItemContent
              mdCol={4}
              label="Puente Project"
              values={EducationalPrograms?.puente_project}
            />
            {formatBoolean(EducationalPrograms?.puente_project) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in Puente Project"
                values={EducationalPrograms?.year_participated_in_puente_project}
              />
            )}
          </Row>

          <Row>
            <ItemContent
              mdCol={4}
              label="Independent Living Project (ILP)"
              values={EducationalPrograms?.ilp}
            />
            {formatBoolean(EducationalPrograms?.ilp) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in Independent Living Project (ILP)"
                values={EducationalPrograms?.year_participated_in_ilp}
              />
            )}
          </Row>

          <Row>
            <ItemContent
              mdCol={4}
              label="MESA Project"
              values={EducationalPrograms?.MESA_project}
            />
            {formatBoolean(EducationalPrograms?.MESA_project) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in MESA Project"
                values={EducationalPrograms?.year_participated_in_MESA_project}
              />
            )}
          </Row>

          <Row>
            <ItemContent
              mdCol={4}
              label="Other (CAMP, EAOP, EOP&S/CARE GEAR-UP, TRIO, or Foster youth support programs)"
              values={EducationalPrograms?.other_program}
            />
            {formatBoolean(EducationalPrograms?.other_program) === true && (
              <ItemContent
                mdCol={8}
                label="Number of years you participated in Gear-up, CAMP, Foster youth support programs, Student Support Services Programs (SSSP)"
                values={EducationalPrograms?.year_participated_in_other_program}
              />
            )}
          </Row>

          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">CBOs & Fee Waiver</div>
          <p>Community-Based Organization (CBO) </p>

          <ItemContent
            label="Are you participating in a Community-Based Organization (CBO)?"
            values={citizenshipData?.is_participate_cbo}
            mdCol={12}
          />
          <ItemContent
            mdCol={12}
            values={citizenshipData?.indicate_number_community_programs}
            label="Indicate the number of community programs or organizations that have provided you with free
        assistance in your application process.*"
          />
          <ItemContent
            label="Your CBOs"
            values={(checkEnableParseJSON(citizenshipData?.cbo_info_array) || []).map(
              (e: any) => e?.organization ?? e?.otherOrganization
            )}
            mdCol={12}
          />
          <p>CBO Counselor / Advisor / Mentor</p>
          <ItemContent label="Prefix" values={citizenshipData?.advisor_prefix} />
          <ItemContent label="First Name" values={citizenshipData?.advisor_first_name} />
          <ItemContent label="Last Name" values={citizenshipData?.advisor_last_name} />
          <ItemContent label="Phone number" values={citizenshipData?.advisor_phone_number} />
          <ItemContent mdCol={12} label="Email" values={citizenshipData?.advisor_email} />
          <div className="border w-100 my-3" />
          <div className="fs-5 text-field-light">Fee Waiver</div>
          <ItemContent
            mdCol={12}
            label="Do you meet one or more of the Common App fee waiver, Opens in new tab, eligibility criteria?*"
            values={citizenshipData?.is_financial_qualify_fee_waiver}
          />
          {formatBoolean(citizenshipData?.is_financial_qualify_fee_waiver) === true && (
            <ItemContent
              mdCol={12}
              label="I certify that I understand and meet the eligibility requirements to request an
              admission application fee waiver. I also understand if I am a first year student my
              counselor will be asked to verify my eligibility. Fee Waiver signature"
              values={citizenshipData?.fee_waiver_signature}
            />
          )}
          <ItemContent
            label="Would you like to connect with a UStrive mentor?"
            mdCol={12}
            values={
              citizenshipData?.indicator_economic_fee_waiver_array
                ?.is_like_to_receive_information_from_strive_for_college
            }
          />
        </>
      )}
    </Row>
  );
};

export const SummaryOther = () => {
  const [citizenshipData, setCitizenshipData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await basicInformationServices.getCitizenship(params.id);
      setCitizenshipData({
        ...res,
        csu_info: checkEnableParseJSON(res?.csu_info),
        statement_legal_resident: checkEnableParseJSON(res?.statement_legal_resident),
        indicator_economic_fee_waiver_array: checkEnableParseJSON(
          res?.indicator_economic_fee_waiver_array
        ),
      });
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="w-100">
      <div className="fs-5 mt-3">CSU Release Statements</div>
      <div>
        CERTIFICATION - to be read and authorized by all applicants to certify the accuracy of the
        information provided.
      </div>
      <CheckboxContent
        col={12}
        label={
          'Your certification of this statement serves the same purpose as a legal signature, and is binding.'
        }
        values={citizenshipData?.csu_info?.is_certify}
      />
      <div className="fs-5 mt-3">CAASPP Results Release Statement</div>
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.hereby_authorize_CD_release_CAASPP}
        label="I hereby authorize the California Department of Education to release my California Assessment of Student Performance and Progress (CAASPP) score report to the California State University for the purpose of credit, placement, determination of readiness for college-level coursework, or admission."
      />
      <div className="fs-5 mt-3">Release of Contact Information</div>
      <CheckboxContent
        col={12}
        label="Check box to Acknowledge"
        values={citizenshipData?.csu_info?.is_authorize_CSU_release_contact_information}
      />
      <div className="fs-5 mt-3">Additional Information Release</div>
      <CheckboxContent
        col={12}
        label="I authorize the California State University to release to my school or college counselor/counseling office (or sponsoring agency) information regarding my application, including test scores, transcripts and other supporting documents, as they relate to my admission and scholarship status. If you do not check the box, CSU may not inform your school or counselor whether you have applied or been admitted."
        values={citizenshipData?.csu_info?.is_authorize_CSU_release_my_application}
      />
      <div className="fs-5 mt-3">Campus Housing</div>

      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.is_interested_on_campus_space_available}
        label="Are you interested in on campus housing should space be available? Note: housing availability is subject to your submission of a separate campus housing application and availability."
      />
      <div className="fs-5 mt-3">Applicant Information</div>
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.in_foster_care}
        label="At any time since you turned age 13, were both your parents deceased, were you in foster care or were you a dependent or ward of the court?"
      />
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.is_emancipated_minor}
        label="As determined by a court in your state of legal residence, are you or were you an emancipated minor?"
      />
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.is_homeless_liaison_determine}
        label="At any time on or after July 1, 2023, did your high school or school district homeless liaison determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.is_director_or_transitional_determine}
        label="At any time on or after July 1, 2023, did the director of an emergency shelter or transitional housing program funded by the U.S. Department of Housing and Urban Development determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.is_director_of_runaway_or_homeless_youth_basic}
        label="At any time on or after July 1, 2023, did the director of a runaway or homeless youth basic center or transitional living program determine that you were an unaccompanied youth who was homeless or were self-supporting and at risk of being homeless?"
      />

      <div className="fs-5 p-1 mt-4">Statewide Student ID</div>
      <ItemContent
        mdCol={12}
        label="Have you ever attended a California public school?"
        values={citizenshipData?.csu_info?.attended_California_public_school}
      />
      {formatBoolean(citizenshipData?.csu_info?.attended_California_public_school) === true && (
        <>
          <ItemContent
            mdCol={12}
            label="California Statewide Student Identifier (SSID)"
            values={citizenshipData?.csu_info?.ssid}
          />
          <CheckboxContent
            label="I authorize the release of my CA SSID Number and application status to California Guidance College Initiative (CCGI) for the purpose of tracking my UC application. I understand that the information will only be shared if my school has provided my transcript to CCGI."
            values={citizenshipData?.csu_info?.authorize_release_CASSID_for_tracking_UC_application}
          />
        </>
      )}

      <div className="fs-5 mt-3">Academic Standing</div>
      <ItemContent
        mdCol={12}
        label="Are you in good standing with your last school attended?"
        values={citizenshipData?.csu_info?.is_in_good_standing}
      />
      <div className="fs-5 mt-3">Academic Infractions and Conduct</div>
      <ItemContent
        mdCol={12}
        label="Have you ever been disciplined for academic performance (e.g. academic probation, disqualification, etc.) by any college or university?"
        values={citizenshipData?.csu_info?.is_disciplined_for_academic_performance}
      />
      <ItemContent
        mdCol={12}
        label="Have you been academically disqualified at any college or university?"
        values={citizenshipData?.csu_info?.is_academically_disqualified}
      />
      <ItemContent
        mdCol={12}
        label="Have you ever been disciplined for student conduct violations (e.g. suspension, expulsion, etc.) by any college or university?"
        values={citizenshipData?.csu_info?.is_disciplined_for_student_conduct_violations}
      />
      <div className="fs-5 mt-3">Teacher or Other Education Credential Information</div>
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.interest_in_teacher_or_educational_credential}
        label="Select your interest in obtaining your teacher or other educational credential."
      />
      <div className="fs-5 mt-3">CalFresh</div>
      <ItemContent
        mdCol={12}
        label="Are you interested in checking your eligibility for monthly food assistance to be used towards groceries through the CalFresh Program?"
        values={citizenshipData?.csu_info?.is_interested_eligibility_assistance_CalFresh}
      />
      <div className="fs-5 mt-3">CA Promise</div>
      <ItemContent
        mdCol={12}
        label="Are you interested in participating in the CA Promise?"
        values={citizenshipData?.csu_info?.is_interested_participating_CA_Promise}
      />
      <div className="fs-5 mt-3">RN License</div>
      <ItemContent
        mdCol={8}
        values={citizenshipData?.csu_info?.have_rn_license}
        label="*Do you have an RN (registered nurse) license or expect to achieve a RN license?"
      />
      {formatBoolean(citizenshipData?.csu_info?.have_rn_license) === true && (
        <ItemContent mdCol={4} values={citizenshipData?.csu_info?.rn_license} label="RN License" />
      )}

      <div className="fs-5 mt-3">How did you Hear About Us?</div>
      <ItemContent
        mdCol={12}
        values={citizenshipData?.csu_info?.how_hear_about_CalState}
        label="How did you hear about CalState.edu/apply?"
      />
      {/* <ItemContent
        mdCol={12}
        label="Have you ever attended a California public school?"
        values={citizenshipData?.csu_info?.attended_California_public_school}
      />
      {citizenshipData?.csu_info?.attended_California_public_school === 'true' && (
        <>
          <ItemContent
            mdCol={12}
            values={citizenshipData?.csu_info?.ssid}
            label="California Statewide Student Identifier (SSID)"
          />
        </>
      )}

     
      {citizenshipData?.csu_info?.is_disciplined_for_student_conduct_violations === 'true' && (
        <ItemContent
          mdCol={12}
          values={citizenshipData?.csu_info?.explain_for_each_violation}
          label="If yes, please explain and disclose institution for each violation."
        />
      )}
     
      <h6>CalFresh</h6> */}
      <div className="border w-100 my-3" />
      <div className="fs-5 mt-3">UC Release Statements</div>

      <div className="fs-5 mt-3">Community principles</div>
      <div className="text-field-light p-1">
        We are a community of people from diverse backgrounds, with diverse identities, abilities,
        and needs. Everything we do is supported and enriched by an atmosphere of positive
        engagement and a spirit of cooperation and inquiry.
        <br />
        Each UC campus has committed to Principles of Community that reaffirm each individual’s
        right to dignity and civil discourse. We ask you to agree that, if you become part of the UC
        community, you will seek to maintain a climate of civility and mutual respect.
        <br />
        The following principles serve as a guide and reflect our passion for critical inquiry and
        our deep commitment to contributing to a better world:
        <br />
        — We place honesty and integrity in our teaching, learning, research and administration at
        the highest level.
        <br />
        — We respect the differences as well as the commonalities that bring us together.
        <br />
        — We reject acts of discrimination based on race, ethnicity, sex, gender identity, age,
        disability, sexual orientation, religion, and political beliefs.
        <br />— We are committed to ensuring freedom of expression. We celebrate the expression of
        individuality and diversity within the bounds of courtesy, sensitivity, and respect.
      </div>

      <CheckboxContent
        col={12}
        label="I acknowledge, value, and agree with these guiding principles.*"
        values={citizenshipData?.csu_info?.agree_with_guiding_principles}
      />
    </Row>
  );
};
