/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { MenuItem, ListItemText, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';
import TextInput from 'components/input/textInput';
import { CssSelect } from 'components/helper/helperInut';
import SelectInput from 'components/input/SelectInput';
import { optionValues } from 'components/helper/helper';

export const A_F_Grade = [
  { value: 'A+' },
  { value: 'A'  },
  { value: 'A-' },
  { value: 'B+' },
  { value: 'B'  },
  { value: 'B-' },
  { value: 'C+' },
  { value: 'C'  },
  { value: 'C-' },
  { value: 'D+' },
  { value: 'D'  },
  { value: 'D-' },
  { value: 'F'  },
];

export const genGradeOptions = (gradingScale:string) => {
  switch (gradingScale) {
    case 'A-F':
      return A_F_Grade;
    case '1-100':
      return [...optionValues(Array.from({ length: 100 }, (_, i) => i + 1))];
    case '0.0-4.0':
      return [ ...optionValues(Array.from({ length: 41 }, (_, i) => i / 10))];
    case '0.0-5.0':
      return [ ...optionValues(Array.from({ length: 51 }, (_, i) => i / 10))];
    case '1-7':
      return [...optionValues(Array.from({ length: 7 }, (_, i) => i + 1))];
    case '1-10':
      return [...optionValues(Array.from({ length: 10 }, (_, i) => i + 1))];
    case '1-11':
      return [ ...optionValues(Array.from({ length: 11 }, (_, i) => i + 1))];
    case '1-20':
      return [...optionValues(Array.from({ length: 20 }, (_, i) => i + 1))];
    default:
      return [];
  }
}

export const genTermOptions= (schedule: string) => {
  let termOptions:any[];
  termOptions= [
    { name: 'Final', value: 'Final'},
    { name: 'Semester 1', value: 'Semester 1'},
    { name: 'Semester 2', value: 'Semester 2'},
    { name: 'Semester 3', value: 'Semester 3'},
    { name: 'Semester 4', value: 'Semester 4'},
  ];
  switch(schedule) {
    case 'Semester':
      termOptions = [
        { name: 'Final', value: 'Final'},
        { name: 'Semester 1', value: 'Semester 1'},
        { name: 'Semester 2', value: 'Semester 2'},
      ];
      break;
    case 'Trimester':
      termOptions = [
        { name: 'Final', value: 'Final'},
        { name: 'Semester 1', value: 'Semester 1'},
        { name: 'Semester 2', value: 'Semester 2'},
        { name: 'Semester 3', value: 'Semester 3'},
      ];
      break;
    case 'Quarters':
       termOptions = [
        { name: 'Final', value: 'Final'},
        { name: 'Semester 1', value: 'Semester 1'},
        { name: 'Semester 2', value: 'Semester 2'},
        { name: 'Semester 3', value: 'Semester 3'},
        { name: 'Semester 4', value: 'Semester 4'},
      ];
      break;
      case 'Yearly':
      case 'Other':
        termOptions = [
          { name: 'Final', value: 'Final'},
        ];
        break;
  }
  return termOptions;
}

type Props = {
  open: boolean;
  options: any[];
  handleSave: (values: any) => void;
  onClose: () => void;
};

const FormCreateGpaForecastModal = ({ options, open, handleSave, onClose }: Props) => {
  const [termOptions, setTermOptions] = React.useState<any[]>(genTermOptions((options && options.length>0) ? options[0].schedule : ''));
  const [gradeOptions, setGradeOptions] = React.useState<any[]>(genGradeOptions((options && options.length>0) ? options[0].grading_scale : ''));
  const [school, setSchool] = React.useState<any>( (options && options.length>0) ? options[0]: null)
  const formik = useFormik({
    initialValues: {
      gpa_forecast_id: (options && options.length>0) ? options[0].gpa_forecast_id : undefined,
      school_name: (options && options.length>0) ? options[0].school_name: '',
      grading_scale: (options && options.length>0) ? options[0].grading_scale: '',
      schedule: (options && options.length>0) ? options[0].schedule: '',
      course: '',
      term: (termOptions && termOptions.length>0) ? termOptions[0].value : 'Final',
      grade: (gradeOptions && gradeOptions.length>0) ? gradeOptions[0].value : '',
      credit: 1,
      is_weight_point: false,
      unweighted_point: '',
      weighted_point: '',
    },
    onSubmit: (values) => {
      handleSave(values);
    },
  });
  React.useEffect(() => {
    !open && formik.resetForm();
  }, [open]);
  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>New GPA Forecast Information</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="pt-2">
            <Row className="pb-2">
              <Form.Group
                  name="gpa_forecast_id"
                  as={Col}
                  md={6}
                  className="p-1 p-md-1"
              >
                  <Form.Label className={`select-label label-14px`}>School Name</Form.Label>
                <div style={{ backgroundColor: 'white', borderRadius: '0.5rem'}}>
                  <CssSelect
                    name = "select_school"
                    value={school}
                    onChange={(e:any) => {
                        let obj:any = e.target.value;
                        if (obj) {
                          formik.setFieldValue('gpa_forecast_id', obj.gpa_forecast_id);
                          formik.setFieldValue('school_name', obj.school_name);
                          formik.setFieldValue('schedule', obj.schedule);
                          setTermOptions(genTermOptions(obj.schedule));
                          setGradeOptions(genGradeOptions(obj.grading_scale));
                        }
                        setSchool(obj);
                    }}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(o:any)=> o.school_name}
                    >
                    {options.map((e) => (
                      <MenuItem key={e.gpa_forecast_id} value={e}>
                        <ListItemText primary={e.school_name} />
                      </MenuItem>
                    ))}
                  </CssSelect>
                </div>
              </Form.Group>
              <TextInput
                mdCol={6}
                label="Course"
                values={formik.values.course}
                name="course"
                onChange={formik.handleChange}
              />
            </Row>
            <Row className="py-2">
              <TextInput
                mdCol={3}
                label="Schedule"
                values={formik.values.schedule}
                name="schedule"
                onChange={formik.handleChange}
                readOnly={true}
              />
              <TextInput
                mdCol={3}
                label="Grading Scale"
                values={formik.values.grading_scale}
                name="grading_scale"
                onChange={formik.handleChange}
                readOnly={true}
              />
              <SelectInput 
                mdCol={3}
                option={termOptions}
                label="Term"
                values={formik.values.term}
                name="term"
                onChange={formik.handleChange}
              />
              <SelectInput 
                mdCol={3}
                option={gradeOptions}
                label="Grade"
                values={formik.values.grade}
                name="grade"
                onChange={formik.handleChange}
                />
            </Row>
            <Row className="py-2">
              <TextInput
                type="number"
                decimalStep={0.1}
                mdCol={4}
                label="Unweighted point"
                values={formik.values.unweighted_point}
                name="unweighted_point"
                onChange={(e:any)=>{
                  formik.setFieldValue('is_weight_point', false);
                  // formik.setFieldValue('weighted_point', 0);
                  formik.handleChange(e);
                }}
              />
              <TextInput
                type="number"
                decimalStep={0.1}
                mdCol={4}
                label="Weighted point"
                values={formik.values.weighted_point}
                name="weighted_point"
                onChange={(e:any)=>{
                  formik.setFieldValue('is_weight_point', true);
                  // formik.setFieldValue('unweighted_point', 0);
                  formik.handleChange(e);
                }}
              />
              <TextInput
                type="number"
                decimalStep={0.1}
                mdCol={4}
                label="Credits"
                values={formik.values.credit}
                name="credit"
                onChange={formik.handleChange}
              />
            </Row>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={() => {
              handleSave(formik.values)
              onClose();
          }}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FormCreateGpaForecastModal;