export default {
  'filter.expand': 'Expand Filter',
  'filter.collapse': 'Collapse Filter',
  'filter.clear': 'Clear Filter',
  'total.prefix': 'Total',
  'total.suffix': 'Rows',
  'add': 'Add',
  'edit': 'Edit',
  'delete': 'Delete',
  'undelete': 'UnDelete',
  'ok': 'Ok',
  'cancel': 'Cancel',
  'op': 'Operation',
  'search': 'Search',
  'columns': 'Columns',
  'download': 'Download',
  'select': 'Select Record',
  'collapse': 'Collapse Table',
  'expand': 'Expand Table',
  'empty': 'No Data',
};
